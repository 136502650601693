import { Ref, forwardRef, useMemo, useRef } from 'react';
import { GroupBase, SingleValue, StylesConfig } from 'react-select';
import LibAsyncSelect from 'react-select/async';
import styled, { css } from 'styled-components';
import { Typography } from 'components/typography';
import { themeProvider } from 'theme';
import Select from 'react-select/dist/declarations/src/Select';
import { SelectOptionsType } from './types';

const Container = styled.div<{ $error: boolean }>`
  display: flex;
  row-gap: ${({ theme }) => theme.spacing(1)};
  flex-direction: column;
  width: 100%;

  input {
    border-color: ${({ $error, theme }) =>
      $error ? theme.palette.orange : theme.palette.black};
  }
`;

const InnerContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: flex-start;
  column-gap: ${({ theme }) => theme.spacing(1)};

  ${({ disabled }) =>
    disabled &&
    css`
      svg {
        circle,
        path {
          stroke: ${({ theme }) => theme.palette.gray};
        }
      }
    `}
`;

export interface AsyncSelectProps {
  /** Размер поля */
  size?: 'sm' | 'lg';
  /** Текст плейсхолдера */
  placeholder?: string;
  /** Текст лейбла */
  label?: string;
  /** Реф */
  ref?: Ref<HTMLInputElement | null>;
  /** Текст ошибки */
  error?: string;
  /** Текст при отсутсвии подходящих опций */
  noOptionsMessage?: string;
  /** Текст при загрузке опций */
  loadingMessage?: string;
  /** Значение */
  value?: SingleValue<SelectOptionsType>;
  /** Дефолтные опции */
  defaultOptions?: SelectOptionsType[];
  /** Функция для получения опций, выполняется также при вводе данных в поле */
  onInputChange?: (value: string) => Promise<SelectOptionsType[]>;
  /** Функция для получения выбранной опции */
  onSelect: (selectedOption: SelectOptionsType) => void;
  /** Флаг активности */
  disabled?: boolean;
  /** Функция для обработки потери фокуса */
  onBlur?: (e?: any) => void;
}

const AsyncSelect: React.FC<AsyncSelectProps> = forwardRef<
  HTMLInputElement | null,
  AsyncSelectProps
>(
  ({
    placeholder = 'Выберите значение...',
    label,
    error,
    noOptionsMessage = 'Пусто',
    loadingMessage = 'Загрузка...',
    value,
    defaultOptions,
    onInputChange,
    onSelect,
    disabled = false,
    size = 'sm',
    onBlur,
  }) => {
    /** Кастомная стилизация компонента react-select */
    const InputStyles: StylesConfig<SelectOptionsType, false> = useMemo(
      () => ({
        option: (styles, state) => ({
          ...styles,
          backgroundColor: state.isSelected
            ? themeProvider.palette.green
            : themeProvider.palette.white,
        }),
        clearIndicator: (styles) => ({
          ...styles,
          padding: 0,
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        multiValueRemove: (styles) => ({
          ...styles,
          color: themeProvider.palette.gray,
        }),
        container: (styles) => ({
          ...styles,
          flex: 1,
          maxWidth: '100%',
        }),
        menu: (styles, state) => {
          if (
            state.options.length ||
            state.isLoading ||
            state.selectProps.inputValue
          ) {
            return {
              ...styles,
              display: 'initial',
            };
          }
          return { display: 'none' };
        },
        input: (styles) => ({
          ...styles,
          padding: 0,
          margin: 0,
          overflow: 'hidden',
        }),
        valueContainer: (styles) => ({
          ...styles,
          padding: 0,
        }),
        control: (styles, state) => ({
          ...styles,
          backgroundColor: themeProvider.palette.white,
          padding: '0 8px',
          border: `1px solid ${themeProvider.palette.black} !important`,
          boxShadow: 'none',

          minHeight: size === 'sm' ? '32px' : '40px',
          fontSize: size === 'sm' ? '0.875rem' : '1rem',
        }),
        indicatorSeparator: (styles) => ({
          ...styles,
          display: 'none',
        }),
        indicatorsContainer: (styles) => ({
          ...styles,
          marginRight: '-4px',
          marginLeft: '5px',
        }),
        dropdownIndicator: (styles) => ({
          ...styles,
          display: 'unset',
          padding: 0,
        }),
      }),
      [size],
    );

    const Label = useMemo(() => {
      if (!label) return null;

      if (typeof label === 'string')
        return (
          <Typography
            color={
              disabled
                ? themeProvider.palette.gray
                : themeProvider.palette.black
            }
          >
            {label}
          </Typography>
        );

      return label;
    }, [disabled, label]);

    const selectRef = useRef<
      Select<SelectOptionsType, true, GroupBase<SelectOptionsType>> | undefined
    >();

    return (
      <Container $error={!!error}>
        {Label}

        <InnerContainer disabled={disabled}>
          <LibAsyncSelect
            placeholder={placeholder}
            // @ts-ignore
            ref={selectRef}
            isMulti={false}
            defaultOptions={defaultOptions}
            isDisabled={disabled}
            loadOptions={onInputChange}
            loadingMessage={() => loadingMessage}
            menuPosition="absolute"
            noOptionsMessage={() => noOptionsMessage}
            onChange={(e) => {
              const option = e as SingleValue<SelectOptionsType>;
              onSelect({
                value: option?.value ?? '',
                label: option?.label ?? '',
              });
            }}
            styles={InputStyles}
            value={value}
            onBlur={onBlur}
            cacheOptions
            isSearchable
            isClearable
            openAfterFocus
          />
        </InnerContainer>

        {error ? (
          <Typography variant="caption" color={themeProvider.palette.orange}>
            {error}
          </Typography>
        ) : null}
      </Container>
    );
  },
);

AsyncSelect.displayName = 'AsyncSelect';

export default AsyncSelect;
