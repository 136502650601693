import React from 'react';
import styles from './task-list-header.module.css';

export const TaskListHeaderDefault: React.FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}> = ({ headerHeight, fontFamily, fontSize, rowWidth }) => (
  <div
    className={styles.ganttTable}
    style={{
      fontFamily,
      fontSize,
    }}
  >
    <div
      className={styles.ganttTable_Header}
      style={{
        height: headerHeight - 2,
      }}
    >
      <div
        className={styles.ganttTable_HeaderItem}
        style={{
          minWidth: rowWidth,
        }}
      >
        &nbsp;Name
      </div>
      <div
        className={styles.ganttTable_HeaderSeparator}
        style={{
          height: headerHeight * 0.5,
          marginTop: headerHeight * 0.2,
        }}
      />
      <div
        className={styles.ganttTable_HeaderItem}
        style={{
          minWidth: rowWidth,
        }}
      >
        &nbsp;From
      </div>
      <div
        className={styles.ganttTable_HeaderSeparator}
        style={{
          height: headerHeight * 0.5,
          marginTop: headerHeight * 0.25,
        }}
      />
      <div
        className={styles.ganttTable_HeaderItem}
        style={{
          minWidth: rowWidth,
        }}
      >
        &nbsp;To
      </div>
    </div>
  </div>
);
