import { months } from 'lib/enums/Month';
import { CSSProperties, FC } from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: ${({ theme }) => theme.spacing(1)};
  position: relative;
  overflow-x: scroll;

  // Hide scrollbar
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    width: 30px;
    height: 100%;
    background: linear-gradient(to left, #fff 20%, rgba(33, 33, 33, 0) 80%);
    position: absolute;
    right: 0;
  }
`;

const MonthItem = styled.button<{ $selected: boolean }>`
  ${({ theme }) => ({ ...theme.typography.body2 })}
  outline: none;
  border: ${({ $selected, theme }) =>
    $selected ? `2px solid ${theme.palette.lgreen}` : 'none'};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 144px;
  height: 24px;
  flex-shrink: 0;
`;

interface Props {
  selected?: number;
  style?: CSSProperties;
  onChange: (value: number) => void;
}

const MonthFilter: FC<Props> = ({ selected, style, onChange }) => (
  <Container style={style}>
    {months.map((month, idx) => (
      <MonthItem
        key={`${idx}_${month}`}
        $selected={idx + 1 === selected}
        onClick={() => onChange(idx + 1)}
      >
        {month}
      </MonthItem>
    ))}
  </Container>
);

export default MonthFilter;
