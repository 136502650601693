import { PboTypeModelCollection } from 'store/dto/PboTypeModelCollection';
import { api } from './api';

export interface PboAllRequest {
  Offset: number;
  Size: number;
}

export const pboApi = api.injectEndpoints({
  endpoints: (builder) => ({
    pboAll: builder.query<PboTypeModelCollection, PboAllRequest>({
      query: (params) => ({
        url: '/Pbo/all',
        params,
      }),
    }),
  }),
});

export const { usePboAllQuery } = pboApi;
