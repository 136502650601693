import { ChangeEvent, FC, forwardRef, ReactNode, Ref, useMemo } from 'react';

import styled, { css } from 'styled-components';
import { Form, FormControlProps, Spinner } from 'react-bootstrap';
import { COLORS, themeProvider } from 'theme';
import clsx from 'clsx';
import { FieldHelperProps, FieldMetaProps, useFormik } from 'formik';

import { Typography } from '../typography';

const Container = styled.div<{ $error: boolean }>`
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  input {
    border-color: ${({ $error }) =>
      $error ? themeProvider.palette.orange : themeProvider.palette.black};
  }

  &.full-heigth .form-control {
    height: 100%;
  }
`;

const InnerContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)};
  height: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      svg {
        circle,
        path {
          stroke: ${({ theme }) => theme.palette.gray};
        }
      }
    `}
`;

const ErrorBox = styled.div`
  padding-top: ${({ theme }) => theme.spacing(1)};

  &.floating-error {
    padding-top: 0;
    position: relative;

    & > p {
      position: absolute;
      z-index: 99999;
      top: 0;
      padding-top: 5px;
      width: 100%;
      font-size: 12px;
      line-height: 1;

      /* background-color: white; */

      /* border-bottom: 1px solid ${COLORS.orange15}; */
    }
  }
`;

export type FormFieldProps = FormControlProps &
  Partial<FieldMetaProps<any>> &
  Partial<FieldHelperProps<any>> & {
    name: string;
    label?: ReactNode;
    startIcon?: ReactNode;
    floatingError?: boolean;
    loading?: boolean;
    autofocus?: boolean;
    ref?: Ref<HTMLInputElement | null>;
    maxLength?: number;
    fullHeight?: boolean;
    InputComponent?: React.ComponentType<any>;
  };

export const register = (
  name: string,
  form: ReturnType<typeof useFormik<any>>,
) => ({
  ...form.getFieldProps(name),
  ...form.getFieldMeta(name),
  ...form.getFieldHelpers(name),
});

export const FormField: FC<FormFieldProps> = forwardRef<
  HTMLInputElement | null,
  FormFieldProps
>(
  (
    {
      label,
      startIcon,
      disabled = false,
      loading = false,
      error = '',
      floatingError = false,
      autofocus = false,
      type,
      value,
      maxLength,
      onChange,
      fullHeight = false,
      InputComponent = Form.Control,
      ...otherProps
    },
    ref,
  ) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

      if (type !== 'number') {
        return onChange?.(e);
      }

      if (Number(newValue) >= 0) {
        return onChange?.(e);
      }

      return null;
    };

    const Label = useMemo(() => {
      if (!label) return null;

      if (typeof label === 'string')
        return (
          <Typography
            color={
              disabled
                ? themeProvider.palette.gray
                : themeProvider.palette.black
            }
          >
            <span style={{ display: 'inline-block', paddingBottom: 8 }}>
              {label}
            </span>
          </Typography>
        );

      return label;
    }, [disabled, label]);

    return (
      <Container
        $error={error.length > 0}
        className={clsx({
          'full-heigth': fullHeight,
        })}
      >
        {Label}

        <InnerContainer disabled={disabled}>
          {startIcon}
          <InputComponent
            disabled={disabled}
            type={type}
            value={value}
            onChange={handleChange}
            ref={ref}
            maxLength={maxLength}
            as={type === 'textarea' ? 'textarea' : undefined}
            {...otherProps}
          />
          {loading && <Spinner animation="border" size="sm" />}
        </InnerContainer>

        {error ? (
          <ErrorBox className={clsx({ 'floating-error': floatingError })}>
            <Typography variant="caption" color={themeProvider.palette.orange}>
              {error}
            </Typography>
          </ErrorBox>
        ) : null}
      </Container>
    );
  },
);
