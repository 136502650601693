import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector as useSelectorBase,
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { api } from './services/api';
import authReducer from './features/authSlice';
import monthColumnsExpandedReducer from './features/monthColumnsExpandedSlice';
import rowsExpandedReducer from './features/expandedRowsSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
};

const monthColumnsExpandedPersistConfig = {
  key: 'monthColumnsExpanded',
  storage,
};

const rowsExpandedPersistConfig = {
  key: 'rowsExpanded',
  storage,
};

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: persistReducer(
      authPersistConfig,
      authReducer,
    ) as unknown as typeof authReducer,
    monthColumnsExpanded: persistReducer(
      monthColumnsExpandedPersistConfig,
      monthColumnsExpandedReducer,
    ) as unknown as typeof monthColumnsExpandedReducer,
    rowsExpanded: persistReducer(
      rowsExpandedPersistConfig,
      rowsExpandedReducer,
    ) as unknown as typeof rowsExpandedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<RootState> = useSelectorBase;
export const useAppDispatch = () => useDispatch<AppDispatch>();
