import { FC } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthLayout, MainLayout } from 'layouts';
import { AuthRoutesEnum } from 'lib';
import { HomePage, LoginPage } from 'pages';
import { GanttPage } from 'pages/GanttPage/GanttPage';
import { ProjectPage } from 'pages/ProjectPage/ProjectPage';

const RootRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path={AuthRoutesEnum.LOGIN} element={<LoginPage />} />
        </Route>

        <Route path="*" element={<MainLayout />}>
          <Route
            index
            element={
              process.env.REACT_APP_GANTT ? <ProjectPage /> : <HomePage />
            }
          />
          <Route path="gantt/:projectId" element={<GanttPage />} />
          <Route path="projects" element={<ProjectPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RootRoutes;
