import { css } from 'styled-components';
import { buttonStyle } from './button';
import { cardStyle } from './card';
import { inputStyle } from './input';
import { modalStyle } from './modal';
import { selectStyle } from './select';

export const styleOverrides = css`
  ${cardStyle}
  ${selectStyle}
  ${buttonStyle}
  ${inputStyle}
  ${modalStyle}
`;
