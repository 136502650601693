import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { AuthRoutesEnum } from 'lib/enums/authRoutesEnum';
import { RootState } from './store';

const baseQueryWithHeaders = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_HOST}api/`,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;

    const isLoginPage = document.location.pathname === AuthRoutesEnum.LOGIN;

    if (token && !isLoginPage) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const mutex = new Mutex();

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  const result = await baseQueryWithHeaders(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    document.location = AuthRoutesEnum.LOGIN;

    // if (!mutex.isLocked()) {
    //   const release = await mutex.acquire()
    //   try {
    //     const refreshResult = await baseQuery(
    //       '/refreshToken',
    //       api,
    //       extraOptions
    //     )
    //     if (refreshResult.data) {
    //       api.dispatch(tokenReceived(refreshResult.data))
    //       result = await baseQueryWithHeaders(args, api, extraOptions)
    //     } else {
    //       api.dispatch(loggedOut())
    //     }
    //   } finally {
    //     release()
    //   }
    // } else {
    //   // wait until the mutex is available without locking it
    //   await mutex.waitForUnlock()
    //   result = await baseQueryWithHeaders(args, api, extraOptions)
    // }
  }
  return result;
};
