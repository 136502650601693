import { CSSProperties, FC, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

interface Props extends CSSProperties {
  children?: ReactNode;
  ref?: any;
}

const Container = styled.div`
  display: flex;
`;

const FlexContainer: FC<Props> = forwardRef(
  ({ children, ...otherProps }, ref) => (
    <Container ref={ref as any} style={otherProps}>
      {children}
    </Container>
  ),
);

export default FlexContainer;
