import { CSSProperties } from 'react';

export type TypographyVariants =
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption';

export type ITypography = Record<TypographyVariants, CSSProperties>;

export const typography: ITypography = {
  subtitle1: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
  },
  body1: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
  },
  body2: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  caption: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15px',
  },
};
