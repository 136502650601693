import { CSSProperties, FC, forwardRef, ReactNode } from 'react';

import { Button, ButtonProps } from 'react-bootstrap';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  ${({ theme }) => ({ ...theme.typography.body1 })};
  color: ${({ theme }) => theme.palette.black};
  padding: 0;
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(2)};
  padding-inline: ${({ theme }) => theme.spacing(1)};
  text-decoration: none;
` as any; // Expression produces a union type that is too complex to represent. ts(2590)

export type LinkButtonProps = {
  children?: ReactNode;
} & CSSProperties &
  ButtonProps;

const LinkButton: FC<LinkButtonProps> = forwardRef(
  ({ children, onClick, ...otherProps }, ref) => (
    <StyledButton variant="link" style={otherProps} onClick={onClick} ref={ref}>
      {children}
    </StyledButton>
  ),
);

export default LinkButton;
