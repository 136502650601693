import { CSSProperties, FC, forwardRef, ReactNode } from 'react';

import { COLORS } from 'theme/colors';
import { TypographyVariants } from 'theme';
import { TextComponent } from './styled';

interface Props {
  color?: string;
  children?: ReactNode;
  variant?: TypographyVariants;
  style?: CSSProperties;
}

const Typography: FC<Props> = forwardRef(
  ({ children, variant = 'body1', color = COLORS.black, style }, ref) => (
    <TextComponent
      variant={variant}
      style={style}
      color={color}
      ref={ref as any}
    >
      {children}
    </TextComponent>
  ),
);

export default Typography;
