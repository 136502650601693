import { FC } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { AppBar, AppHeader } from 'components';
import { useAuth } from 'hooks/useAuth';
import { GetDataFilterProvider } from 'hooks/useGetDataFilter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const MainLayout: FC = () => {
  const auth = useAuth();
  const location = useLocation();

  return auth.token ? (
    <GetDataFilterProvider>
      <Container>
        <AppHeader />
        <AppBar />

        <Outlet />
      </Container>
    </GetDataFilterProvider>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default MainLayout;
