import { css } from 'styled-components';

const SCROLLBAR_SIZE = css`
  height: 8px;
  width: 8px;
`;

export const scrollbar = css`
  &::-webkit-scrollbar {
    ${SCROLLBAR_SIZE}
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    ${SCROLLBAR_SIZE}
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.lgreen};
    transition: 0.3s ease all;
  }
`;
