import { FC } from 'react';

import { SignoutIcon } from '../icons';
import LinkButton, { LinkButtonProps } from './LinkButton';

const LogoutButton: FC<LinkButtonProps> = (props) => (
  <LinkButton {...props}>
    Выход
    <SignoutIcon />
  </LinkButton>
);

export default LogoutButton;
