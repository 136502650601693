import { FC } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { FieldHelperProps, FieldMetaProps } from 'formik';
import { TimeInput } from './TimeInput';

type Props = FormControlProps &
  Partial<FieldMetaProps<any>> &
  Partial<FieldHelperProps<any>> & {
    value?: string | null;
  };

export const DateTimeInput: FC<Props> = ({
  size,
  value,
  setValue = () => {},
}) => {
  const date = value || new Date().toISOString();

  const dateValue = date.substring(0, 10);
  const timeValue = `${date.substring(11, 13)}:00`;

  return (
    <div>
      <Form.Control
        size={size}
        type="date"
        style={{
          border: 'none',
          width: 120,
          paddingLeft: 2,
          display: 'inline-block',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          ...(size === 'sm'
            ? {
                paddingTop: 0,
                paddingBottom: 0,
                minHeight: 29,
                height: 29,
              }
            : {}),
        }}
        value={dateValue}
        onChange={(e) => {
          setValue(`${e.target.value}T${timeValue}:00`);
        }}
      />
      <TimeInput
        size={size}
        style={{
          border: 'none',
          width: 80,
          paddingLeft: 0,
          display: 'inline-block',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          ...(size === 'sm'
            ? {
                position: 'relative',
                paddingTop: 0,
                paddingBottom: 0,
                top: 1,
                minHeight: 29,
                height: 29,
              }
            : {}),
        }}
        value={timeValue}
        onChange={(e) => {
          setValue(`${dateValue}T${e.target.value}:00`);
        }}
      />
    </div>
  );
};
