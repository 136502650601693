import moment from 'moment';
import { Column } from '@devexpress/dx-react-grid';
import { DeleteIcon } from 'components/icons/Delete';
import { ChainIcon } from 'components/icons/Chain';
import { DateTimeInput } from 'components/inputs/DateTimeInput';
import {
  FormField,
  FormFieldProps,
  register,
} from 'components/inputs/FormField';
import {
  Grid,
  Table,
  TableColumnResizing,
  TableHeaderRow,
} from 'dx-react-grid-bootstrap5';
import { useFormik } from 'formik';
import { useModal } from 'hooks/useModal';
import { GantInfoTypeId } from 'lib/enums/GantInfoType';
import {
  dateTimeOptions,
  toLocaleDateStringFactory,
} from 'lib/localeDateString';
import yup from 'lib/utils/yup';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GantInfoModel } from 'store/dto/GantInfoModel';
import {
  useCreateGanttMutation,
  useDeleteGanttMutation,
  useGanttInfoQuery,
  useUpdateGanttMutation,
} from 'store/services/gantt';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { useAuth } from 'hooks/useAuth';

import { TrustModal } from 'components/auth/TrustModal';

export const ProjectPage = () => {
  const toLocaleDateString = toLocaleDateStringFactory('ru-RU');

  const { isAuth, isGuest } = useAuth();
  const { data: ganttInfo = [], refetch } = useGanttInfoQuery(isGuest);

  const [editableRecord, setEditableRecord] = useState<GantInfoModel | null>(
    null,
  );

  const [trustProjectId, setTrustProjectId] = useState<null | number>(null);

  const [createGantt] = useCreateGanttMutation();
  const [updateGantt] = useUpdateGanttMutation();
  const [deleteGantt] = useDeleteGanttMutation();

  const modalHook = useModal();

  function getMinDate() {
    return moment().subtract(5, 'years').format('l');
  }

  function getMaxDate() {
    return moment().add(10, 'years').format('l');
  }

  const validationSchema = yup.object().shape({
    name: yup.string().min(1).max(100).required().label('Наименование проекта'),
    agent: yup.string().min(1).max(50).required().label('Ответственный'),
    note1: yup.string().label('Примечание'),

    startDate: yup
      .date()
      .min(getMinDate(), `Минимальная дата начала: ${getMinDate()}`)
      .max(yup.ref('endDate'), 'Дата начала позже даты завершения.')
      .required()
      .label('Дата начала'),
    endDate: yup.lazy(() =>
      yup
        .date()
        .min(yup.ref('startDate'), 'Дата начала позже даты завершения.')
        .max(getMaxDate(), `Максимальная дата окончания ${getMaxDate()}`)
        .required()
        .label('Дата завершения'),
    ),
  });

  const form = useFormik({
    initialValues: {
      name: editableRecord?.name!,
      startDate: editableRecord?.startDate!,
      endDate: editableRecord?.endDate!,
      agent: editableRecord?.agent!,
      note1: editableRecord?.note1!,
      status: editableRecord?.status!,
    },
    validationSchema,
    onSubmit: (values) => {
      if (form.dirty) {
        console.log(values);
      }
    },
  });

  useEffect(() => {
    if (editableRecord) {
      form.setValues({
        name: editableRecord.name!,
        startDate: editableRecord.startDate!,
        endDate: editableRecord.endDate!,
        agent: editableRecord.agent!,
        note1: editableRecord?.note1!,
        status: editableRecord?.status!,
      });
    }
  }, [editableRecord]);

  const getChainCell = (row: GantInfoModel) => {
    if (
      row.id !== editableRecord?.id &&
      isAuth &&
      process.env.REACT_APP_GANTT
    ) {
      return (
        <span title="Поделиться проектом">
          <ChainCell
            width={20}
            height={20}
            onClick={() => setTrustProjectId(row.id)}
          />
        </span>
      );
    }
    return null;
  };

  const getDelCell = (row: GantInfoModel) => {
    if (row.id !== editableRecord?.id && isAuth) {
      return (
        <span title="Удалить проект">
          <DeleteCell
            width={20}
            height={20}
            onClick={() => deleteProject(row)}
          />
        </span>
      );
    }
    return null;
  };

  const columns: Column[] = [
    {
      name: '_delete',
      title: ' ',
      getCellValue: (row) => getDelCell(row),
    },
    {
      name: '_chain',
      title: ' ',
      getCellValue: (row) => getChainCell(row),
    },
    {
      name: 'name',
      title: 'Наименование проекта',
      getCellValue: (row) =>
        row.id === editableRecord?.id ? (
          <EditableTableCell
            autoFocus
            {...register('name', form)}
            placeholder="Наименование проекта"
          />
        ) : (
          <Link to={`/gantt/${row.id}`}>{row.name}</Link>
        ),
    },
    {
      name: 'agent',
      title: 'Ответственный',
      getCellValue: (row) =>
        row.id === editableRecord?.id ? (
          <EditableTableCell
            {...register('agent', form)}
            placeholder="Ответственный"
          />
        ) : (
          row.agent
        ),
    },
    {
      name: 'startDate',
      title: 'Дата начала',
      getCellValue: (row) =>
        row.id === editableRecord?.id ? (
          <FormField
            InputComponent={DateTimeInput}
            {...register('startDate', form)}
          />
        ) : (
          toLocaleDateString(new Date(row.startDate), dateTimeOptions)
        ),
    },
    {
      name: 'endDate',
      title: 'Дата завершения',
      getCellValue: (row) =>
        row.id === editableRecord?.id ? (
          <FormField
            InputComponent={DateTimeInput}
            {...register('endDate', form)}
          />
        ) : (
          toLocaleDateString(new Date(row.endDate), dateTimeOptions)
        ),
    },
    {
      name: 'note1',
      title: 'Примечание',
      getCellValue: (row) =>
        row.id === editableRecord?.id ? (
          <EditableTableCell
            {...register('note1', form)}
            placeholder="Примечание"
          />
        ) : (
          row.note1
        ),
    },
  ];

  const columnWidths = [
    { columnName: '_delete', width: 50 },
    { columnName: '_chain', width: 50 },
    { columnName: 'name', width: 215 },
    { columnName: 'agent', width: 150 },
    { columnName: 'startDate', width: 215 },
    { columnName: 'endDate', width: 215 },
    { columnName: 'note1', width: 200 },
  ];

  let data = ganttInfo
    .filter((x) => x.parentId === null)
    .sort((a, b) => {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      return 0;
    });

  if (editableRecord?.id === 0) {
    data = [...data, editableRecord];
  }

  const handleDelete = useCallback(
    async (project: GantInfoModel, modalId: number) => {
      try {
        await deleteGantt(project.id).unwrap();
        refetch();
        modalHook.close(modalId);

        modalHook.open({
          okButton: true,
          variant: 'success',
          title: 'Удаление проекта',
          bodyRenderer: () => (
            <span>
              Проект <b>{project.name}</b> успешно удален
            </span>
          ),
        });
      } catch (e) {
        modalHook.open({
          okButton: true,
          variant: 'error',
          title: 'Удаление проекта',
          bodyRenderer: () => (
            <span>
              Ошибка при удалении проекта <b>{project.name}</b>
            </span>
          ),
        });
      }
    },
    [modalHook, deleteGantt, refetch],
  );

  const deleteProject = useCallback(
    async (project: GantInfoModel) =>
      new Promise((resolve, reject) => {
        const modalId = modalHook.open({
          title: 'Удаление проекта',
          bodyRenderer: () => (
            <Stack gap={3} style={{ justifyContent: 'center' }}>
              <span style={{ textAlign: 'center' }}>
                Удалить проект <b>{project.name}</b>?
              </span>
              <Stack
                direction="horizontal"
                gap={3}
                style={{ justifyContent: 'center' }}
              >
                <Button
                  variant="outline-warning"
                  onClick={() =>
                    handleDelete(project, modalId).then(resolve).catch(reject)
                  }
                >
                  Удалить
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => modalHook.close(modalId)}
                >
                  Отмена
                </Button>
              </Stack>
            </Stack>
          ),
          onClose: () => modalHook.close(modalId),
        });
      }),
    [modalHook, handleDelete],
  );

  const createProject = useCallback(() => {
    setEditableRecord({
      id: 0,
      parentId: null,
      name: '',
      typeId: GantInfoTypeId.Project,
      agent: '',
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      note1: '',
      note2: '',
      note3: '',
      status: false,
    });
  }, [setEditableRecord]);

  const cancelEditProject = useCallback(() => {
    setEditableRecord(null);
  }, [setEditableRecord]);

  const saveProject = useCallback(async () => {
    if (editableRecord === null) return;

    if (!form.isValid) {
      return;
    }

    const gantBaseObj = {
      typeId: editableRecord.typeId,
      agent: form.values.agent,
      startDate: form.values.startDate.endsWith('Z')
        ? form.values.startDate
        : `${form.values.startDate}.000Z`,
      endDate: form.values.endDate.endsWith('Z')
        ? form.values.endDate
        : `${form.values.endDate}.000Z`,
      name: form.values.name,
      parentId: editableRecord.parentId,
      note1: form.values.note1,
      note2: '',
      note3: '',
      status: form.values.status,
    };

    if (editableRecord?.id === 0) {
      await createGantt(gantBaseObj).unwrap();
    } else {
      await updateGantt({
        id: editableRecord.id,
        ...gantBaseObj,
      }).unwrap();
    }

    setEditableRecord(null);
    refetch();
  }, [
    editableRecord,
    createGantt,
    updateGantt,
    form,
    setEditableRecord,
    refetch,
  ]);

  const getButtons = () => {
    if (isAuth) {
      return editableRecord ? (
        <>
          <Button
            variant="success"
            size="sm"
            onClick={saveProject}
            disabled={!form.isValid}
          >
            Сохранить
          </Button>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={cancelEditProject}
          >
            Отмена
          </Button>
        </>
      ) : (
        <Button variant="outline-success" size="sm" onClick={createProject}>
          + Создать
        </Button>
      );
    }
    return null;
  };

  return (
    <Stack gap={1} style={{ height: '100px', overflowY: 'auto' }}>
      <Stack
        direction="horizontal"
        gap={3}
        style={{ paddingRight: 6, paddingTop: 12 }}
      >
        <h3 style={{ marginLeft: 30, marginRight: 'auto' }}>Проекты</h3>
        {getButtons()}
      </Stack>
      <Grid rows={data} columns={columns}>
        <Table
          rowComponent={(props) => {
            return (
              <Table.Row
                {...props}
                onClick={({ detail }: any) => {
                  if (isAuth && detail === 2) {
                    return setEditableRecord(props.row);
                  }
                }}
                style={{
                  backgroundColor:
                    props.row.id === editableRecord?.id ? '#ececec' : '#FFFFFF',
                }}
              />
            );
          }}
        />
        <TableColumnResizing defaultColumnWidths={columnWidths} />
        <TableHeaderRow />
      </Grid>
      <TrustModal
        trustProjectId={trustProjectId}
        setTrustProjectId={setTrustProjectId}
      />
    </Stack>
  );
};

const DeleteCell = styled(DeleteIcon)`
  cursor: pointer;
  &:hover {
    color: ${COLORS.orange};
  }
`;

const ChainCell = styled(ChainIcon)`
  cursor: pointer;
  color: ${COLORS.black};
  &:hover {
    color: ${COLORS.orange};
  }
`;

const EditableTableCell = (props: FormFieldProps) => {
  const { value, autoFocus, setValue = () => {}, ...inputProps } = props;

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = value;
  }, [value, inputRef]);

  return (
    <FormField
      ref={inputRef}
      style={{
        border: 'none',
        paddingLeft: '3px',
      }}
      {...inputProps}
      onChange={(e) => {}}
      onBlur={(e) => {
        setValue(e.target.value, true);
      }}
    />
  );
};
