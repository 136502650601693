import { Task } from 'gantt-task-react';
import { TaskType } from 'gantt-task-react/types/public-types';
import { GantInfoTypeId, GantInfoTypeText } from 'lib/enums/GantInfoType';
import { GantInfoModel } from 'store/dto/GantInfoModel';

export const getMetaData = (data: Array<GantInfoModel>, projectId: number) => {
  const levels = getLevels(data);

  const sortedData = sortGanttInfo(data, projectId);

  const tasks: Task[] = sortedData.map((x) => ({
    id: x.id.toString(),
    project: x.parentId?.toString(),
    start: new Date(x.startDate!),
    end: new Date(x.endDate!),
    name: x.name!,
    progress: x.progress!,
    type: getGanttType(x.typeId!),
    hideChildren: false,
    note1: x.note1!,
    status: x.status!,
  }));

  return {
    tasks,
    levels,
  };
};

export const getGanttType = (typeId: GantInfoTypeId): TaskType => {
  switch (typeId) {
    case GantInfoTypeId.Project:
      return GantInfoTypeText.Project;
    case GantInfoTypeId.Milestone:
      return GantInfoTypeText.Milestone;
    case GantInfoTypeId.Task:
      return GantInfoTypeText.Task;
    default:
      return GantInfoTypeText.Project;
  }
};

const getLevels = (ganttData: GantInfoModel[]) => {
  let levels: { id: number; level: number }[] = [];

  const innerFn = (
    arr: GantInfoModel[],
    parentId: number | null,
    level: number,
  ) => {
    arr
      .filter((item) => item.parentId === parentId)
      .forEach((child) => {
        levels = [...levels, { id: child.id, level }];
        innerFn(arr, child.id, level + 1);
      });
  };

  innerFn(ganttData, null, 0);

  return levels;
};

type TaskTree = { task: GantInfoModel; children: TaskTree[] };

const sortGanttInfo = (ganttData: GantInfoModel[], projectId: number) => {
  if (ganttData.length === 0) {
    return [];
  }

  const rootTask = ganttData.find((item) => item.id === projectId)!;

  const taskTree: TaskTree = {
    task: rootTask,
    children: [],
  };

  const innerFn = (arr: GantInfoModel[], parentTask: TaskTree) => {
    let children = arr.filter((item) => item.parentId === parentTask.task.id);
    sortById(children);

    if (children.length === 0) {
      return parentTask;
    }

    const childrenTask = children.filter(
      (item) => item.typeId === GantInfoTypeId.Task,
    );

    const childrenMilestone = children.filter(
      (item) => item.typeId === GantInfoTypeId.Milestone,
    );

    children = [...childrenTask, ...childrenMilestone];

    const mapped = children.map((child) =>
      innerFn(arr, { task: child, children: [] }),
    );

    parentTask.children.push(...mapped);

    return parentTask;
  };

  innerFn(ganttData, taskTree);

  const flattenTree = [taskTree.task];

  taskTree.children.forEach((x) => {
    flattenTree.push(x.task);
    x.children.forEach((y) => {
      flattenTree.push(y.task);
    });
  });

  return flattenTree;
};

const sortById = (tasks: GantInfoModel[]) =>
  tasks.sort((a, b) => {
    if (a.id > b.id) {
      return 1;
    }
    if (a.id < b.id) {
      return -1;
    }

    return 0;
  });
