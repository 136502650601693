import React, { useContext, useState } from 'react';

interface GetDataFilterContextProps {
  year: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
  month: number;
  setMonth: React.Dispatch<React.SetStateAction<number>>;
  showFederalData: boolean;
  setShowFederalData: React.Dispatch<React.SetStateAction<boolean>>;
  city?: string;
  setCity: React.Dispatch<React.SetStateAction<string | undefined>>;
  pboType?: number;
  setPboType: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const GetDataFilterContext = React.createContext<GetDataFilterContextProps>(
  {} as GetDataFilterContextProps,
);

export const useGetDataFilter = () => useContext(GetDataFilterContext);

export const GetDataFilterProvider: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const [year, setYear] = useState(2023);
  const [month, setMonth] = useState(1);
  const [showFederalData, setShowFederalData] = useState(false);
  const [city, setCity] = useState<string>();
  const [pboType, setPboType] = useState<number>();

  return (
    <GetDataFilterContext.Provider
      value={{
        year,
        setYear,
        month,
        setMonth,
        showFederalData,
        setShowFederalData,
        city,
        setCity,
        pboType,
        setPboType,
      }}
    >
      {children}
    </GetDataFilterContext.Provider>
  );
};
