import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'dx-react-grid-bootstrap5/dist/dx-react-grid-bootstrap5.css';
import 'react-contexify/ReactContexify.css';

import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { GlobalStyle, themeProvider } from 'theme';

import { RootRoutes } from 'routes';
import { persistor, store } from 'store/store';
import { ModalProvider } from 'hooks/useModal';

const setEnv = () => {
  const iconlink = document.querySelector('link[rel="icon"]');

  if (iconlink) {
    if (process.env.REACT_APP_GANTT) {
      iconlink.setAttribute('href', 'timeline.png');
      document.title = 'Timeline';
    } else {
      iconlink.setAttribute('href', 'vkusno.ico');
      document.title = 'Вкусно и точка';
    }
  }
};

const App = () => {
  useEffect(() => setEnv(), []);

  return (
    <ThemeProvider theme={themeProvider}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyle />
          <ModalProvider>
            <RootRoutes />
          </ModalProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
