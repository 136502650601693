import React, { memo, useMemo, useCallback, useState } from 'react';
import { getElWithShortStrInTitle, getShortStr } from 'lib';
import { Task } from 'gantt-task-react';
import { styled } from 'styled-components';
import SortDown from 'components/icons/SortDown';
import SortUp from 'components/icons/SortUp';
import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import { useGantt } from 'hooks/useGantt';
import { GantInfoTypeId, GantInfoTypeText } from 'lib/enums/GantInfoType';
import {
  dateTimeOptions,
  toLocaleDateStringFactory,
} from 'lib/localeDateString';
import { useAuth } from 'hooks/useAuth';
import GanttEditForm from './GanttEditForm';
import { TriggerTooltip } from './TriggerTooltip';

export enum ModalOpenLabel {
  CREATE = 'Создание',
  EDIT = 'Редактирование',
}

const TOP_MENU_ID = 'gantt-task-table-top';
const CHILDREN_MENU_ID = 'gantt-task-table-children';

const getName = (t: Task) => {
  const nameObj: Record<string, React.ReactNode> = {
    [GantInfoTypeText.Project]: (
      <NameProject>{getElWithShortStrInTitle(t.name)}</NameProject>
    ),
    [GantInfoTypeText.Milestone]: (
      <NameMilestone>{getElWithShortStrInTitle(t.name)}</NameMilestone>
    ),
    [GantInfoTypeText.Task]: (
      <NameText>{getElWithShortStrInTitle(t.name)}</NameText>
    ),
  };

  return nameObj[t.type];
};

export const TaskListTable: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
  onCreatedTask?: (projectTask: Task) => void;
}> = ({
  rowHeight,
  rowWidth,
  tasks,
  fontFamily,
  fontSize,
  locale,
  onExpanderClick,
  onCreatedTask,
}) => {
  const toLocaleDateString = useMemo(
    () => toLocaleDateStringFactory(locale),
    [locale],
  );

  const { isAuth } = useAuth();

  const {
    ganttInfo,
    createTask,
    editableTask,
    form,
    levels,
    deleteTask,
    editTask,
    cancelEditTask,
  } = useGantt();

  const [isModalOpenAndLabel, setModalOpenAndLabel] =
    useState<ModalOpenLabel | null>(null);

  const handleModalClose = () => {
    setModalOpenAndLabel(null);
  };

  const { show: showTopMenu } = useContextMenu({
    id: TOP_MENU_ID,
  });
  const { show: showChildrenMenu } = useContextMenu({
    id: CHILDREN_MENU_ID,
  });
  const { show: showTaskMenu } = useContextMenu({
    id: CHILDREN_MENU_ID,
  });

  function handleContextMenu(task: Task, event: any) {
    if (isAuth) {
      event.preventDefault();

      const taskLevel = levels.find((l) => l.id.toString() === task.id)?.level;
      if (taskLevel === 0) {
        showTopMenu({
          event,
          props: {
            task,
          },
        });
      }
      if (taskLevel === 1) {
        showChildrenMenu({
          event,
          props: {
            task,
          },
        });
      }
      if (taskLevel === 2) {
        showTaskMenu({
          event,
          props: {
            task,
          },
        });
      }
    }
  }

  const handleCreateMilestoneClick = ({ id, props }: any) => {
    createTask(props.task, GantInfoTypeId.Milestone);
    onCreatedTask!(props.task);
    setModalOpenAndLabel(ModalOpenLabel.CREATE);
  };

  const handleCreateTaskClick = ({ id, props }: any) => {
    createTask(props.task, GantInfoTypeId.Task);
    onCreatedTask!(props.task);
    setModalOpenAndLabel(ModalOpenLabel.CREATE);
  };

  const handleDeleteClick = ({ id, props }: any) => {
    deleteTask(props.task);
  };

  const handleEditDblClick = ({ e, props }: { e: any; props: any }) => {
    if (e.detail === 2) {
      handleEditClick({ props });
    }
  };

  const handleEditClick = useCallback(
    ({ props }: any) => {
      const { task } = props;

      editTask(task);
      setModalOpenAndLabel(ModalOpenLabel.EDIT);
    },
    [editTask],
  );

  return (
    <TaskListWrapper
      style={{
        fontFamily,
        fontSize,
      }}
    >
      {tasks.map((t) => {
        let expanderSymbol = null;

        if (t.type === GantInfoTypeText.Milestone) {
          if (t.hideChildren === false) {
            expanderSymbol = <SortUp />;
          } else if (t.hideChildren === true) {
            expanderSymbol = <SortDown />;
          }
        }

        const rowLevel = levels.find((l) => l.id.toString() === t.id)?.level!;
        const color = t.status ? '#699b68' : '#000000';

        return (
          <TaskListTableRow
            style={{
              height: rowHeight,
              color,
            }}
            key={`${t.id}row`}
            onContextMenu={(e) => handleContextMenu(t, e)}
            onClick={(e: any) => handleEditDblClick({ e, props: { task: t } })}
          >
            <TaskListCell
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
              title={t.name}
            >
              <Indent level={rowLevel} />
              <TaskListNameWrapper>
                {expanderSymbol ? (
                  <TaskListExpander
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onExpanderClick(t);
                    }}
                  >
                    {expanderSymbol}
                  </TaskListExpander>
                ) : (
                  <TaskListEmptyExpander
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onExpanderClick(t);
                    }}
                  >
                    {expanderSymbol}
                  </TaskListEmptyExpander>
                )}

                <NameTextBox>{getName(t)}</NameTextBox>
              </TaskListNameWrapper>
            </TaskListCell>
            <TaskListCell
              style={{
                minWidth: 141,
                maxWidth: 141,
              }}
            >
              <>
                <div style={{ paddingLeft: 3 }}>
                  {toLocaleDateString(t.start, dateTimeOptions)}
                </div>
                <div style={{ paddingLeft: 3 }}>
                  {toLocaleDateString(t.end, dateTimeOptions)}
                </div>
              </>
            </TaskListCell>
            <TaskListCell
              style={{
                minWidth: 143,
                maxWidth: 143,
              }}
            >
              <AgentWrapper>
                <AgentText>
                  {getElWithShortStrInTitle(
                    ganttInfo.find((to) => to.id.toString() === t.id)?.agent,
                  )}
                </AgentText>
                {t.note1 && (
                  <TriggerTooltip text={getShortStr(t.note1, 42)}>
                    <NoteBtnBox>?</NoteBtnBox>
                  </TriggerTooltip>
                )}
              </AgentWrapper>
            </TaskListCell>
          </TaskListTableRow>
        );
      })}
      <Menu id={TOP_MENU_ID}>
        <Item onClick={handleCreateMilestoneClick}>Создать подпроект</Item>
        <Item onClick={handleCreateTaskClick}>Создать задачу</Item>
        <Separator />
        <Item onClick={handleEditClick}>Редактировать</Item>
        <Separator />
        <Item onClick={handleDeleteClick}>Удалить</Item>
      </Menu>
      <Menu id={CHILDREN_MENU_ID}>
        <Item onClick={handleCreateTaskClick}>Создать задачу</Item>
        <Separator />
        <Item onClick={handleEditClick}>Редактировать</Item>
        <Separator />
        <Item onClick={handleDeleteClick}>Удалить</Item>
      </Menu>
      <GanttEditForm
        onClose={() => {
          cancelEditTask();
          handleModalClose();
        }}
        onSubmitted={() => {
          handleModalClose();
        }}
        isModalOpenAndLabel={isModalOpenAndLabel}
      />
    </TaskListWrapper>
  );
};

const Indent = memo(({ level }: { level: number }) => (
  <div style={{ display: 'inline-block' }}>
    {Array.from({ length: level }).map((_value, currentLevel) => (
      <span
        key={currentLevel}
        style={{ display: 'inline-block', position: 'static', width: 5.5 }}
      />
    ))}
  </div>
));

const TaskListWrapper = styled.div`
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
`;

const TaskListTableRow = styled.div`
  display: table-row;
  text-overflow: ellipsis;

  &:hover * {
    background-color: #f5f5f5;
  }
`;

const TaskListCell = styled.div`
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  overflow: visible;
`;

const NameTextBox = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

const NameText = styled.div`
  position: relative;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const NameProject = styled(NameText)`
  font-size: 18px;
  font-weight: 600;
`;

const NameMilestone = styled(NameText)`
  font-weight: 600;
`;

const AgentText = styled.div`
  width: 95px;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const TaskListNameWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const AgentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

const NoteBtnBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #bbbbbb;
  color: #aaaaaa;
  font-size: 10px;
  user-select: none;
  padding: 0;
  line-height: 1;
`;

const TaskListExpander = styled.div`
  color: rgb(86 86 86);
  font-size: 0.6rem;
  padding: 0.15rem 0.2rem 0rem 0.2rem;
  user-select: none;
  cursor: pointer;
`;

const TaskListEmptyExpander = styled.div`
  font-size: 0.6rem;
  padding-left: 5px;
  user-select: none;
`;
