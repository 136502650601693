/* eslint-disable no-template-curly-in-string */
export const LOCALE = {
  mixed: {
    notType: 'Не верный тип данных',
    default: '${path} недействителен',
    required: 'Обязательное поле',
    defined: '${path} должен быть определен',
    oneOf: '${path} должно быть одним из следующих значений: ${values}',
    notOneOf: '${path} не может быть одним из следующих значений: ${values}',
  },
  string: {
    length: '${path} должен состоять ровно из ${length} символов',
    min: 'Не менее ${min} символов',
    max: 'Не более ${max} символов',
    matches: '${path} должен соответствовать следующему: "${regex}"',
    email: 'Введите действительный адрес электронной почты',
    url: '${path} должен быть действительным URL',
    uuid: '${path} должен быть допустимым UUID',
    trim: '${path} должна быть обрезанной строкой',
    lowercase: '${path} должен быть строкой в нижнем регистре',
    uppercase: '${path} должен быть строкой в верхнем регистре',
  },
  number: {
    min: '${path} должен быть больше или равен ${min}',
    max: '${path} должен быть меньше или равен ${max}',
    lessThan: '${path} должен быть меньше ${less}',
    moreThan: '${path} должен быть больше, чем ${more}',
    positive: '${path} должен быть положительным числом',
    negative: '${path} должен быть отрицательным числом',
    integer: '${path} должен быть целым числом',
  },
  date: {
    min: 'Поле ${path} должно быть позже ${min}',
    max: 'Поле ${path} должно быть раньше, чем ${max}',
  },
  boolean: {
    isValue: 'Поле ${path} должно быть ${value}',
  },
  array: {
    min: 'Поле ${path} должно содержать не менее ${min} элементов',
    max: 'В поле ${path} должно быть меньше или равно ${max} элементов',
    length: 'В ${path} должно быть ${length} элементов',
  },
};
