import { GanttProvider } from 'hooks/useGantt';
import { Gantt } from './Gantt';
import { ViewSwitcher } from './ViewSwitcher';

export const GanttPage = () => (
  <GanttProvider>
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <ViewSwitcher />
      <Gantt />
    </div>
  </GanttProvider>
);
