import { css } from 'styled-components';

export const inputStyle = css`
  .form-control {
    border-color: ${({ theme }) => theme.palette.seaport};

    &:disabled {
      background-color: ${({ theme }) => theme.palette.white};
      border-color: ${({ theme }) => theme.palette.gray};
    }

    &:focus {
      box-shadow: none;
      border-color: ${({ theme }) => theme.palette.seaport};
    }

    &-sm {
      height: 32px;
      min-height: 32px;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${({ theme }) => theme.palette.gray};
      opacity: 1; /* Firefox */
      ${({ theme }) => ({ ...theme.typography.caption })}
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${({ theme }) => theme.palette.gray};
      ${({ theme }) => ({ ...theme.typography.caption })}
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${({ theme }) => theme.palette.gray};
      ${({ theme }) => ({ ...theme.typography.caption })}
    }
  }
`;
