import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserInformation } from 'store/dto/AuthResponse';
import {
  selectAuthToken,
  selectUser,
  setCredentials,
} from 'store/features/authSlice';
import { api } from 'store/services/api';

export const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectAuthToken);
  const user = useSelector(selectUser);
  const isGuest = Boolean(user?.isGuest);
  const isAuth = !isGuest;

  const setAuthInfo = useCallback(
    (token: string, user: UserInformation) => {
      navigate('/');
      dispatch(setCredentials({ token, user }));
      dispatch(api.util.resetApiState());
    },
    [navigate, dispatch],
  );

  return { token, user, isAuth, isGuest, setAuthInfo };
};
