import { DefaultTheme } from 'styled-components';
import { COLORS } from './colors';
import { typography } from './typography';
import { fonts } from './fonts';
import { getSpacing } from './spacing';

export const themeProvider: DefaultTheme = {
  palette: COLORS,
  typography,
  fonts,
  spacing: getSpacing,
};
