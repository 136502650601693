import { ReactNode } from 'react';

const defaultMaxLength = 17;
const defaultStr = '';

export const getShortStr = (
  str: string | null | undefined = defaultStr,
  length: number | undefined = defaultMaxLength,
): string => {
  str = str ?? '';
  if (str.length > length) {
    const strShort = `${str.slice(0, length - 3)}...`;
    return strShort;
  }
  return str;
};

export const getElWithShortStrInTitle = (
  str: string | null | undefined,
  length: number | undefined = defaultMaxLength,
): ReactNode => {
  if (str && str.length > length) {
    const strShort = getShortStr(str);
    return <span title={str}>{strShort}</span>;
  }
  return <span>{str}</span>;
};
