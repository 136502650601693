import ModalContainer, {
  ModalContainerProps,
} from 'components/containers/ModalContainer';
import React, { useContext, useState } from 'react';

interface ModalContextProps {
  open: (modalProps: ModalContainerProps) => number;
  close: (idx: number) => void;
}

const ModalContext = React.createContext<ModalContextProps>(
  {} as ModalContextProps,
);

export const useModal = () => useContext(ModalContext);

export const ModalProvider: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const [components, setComponents] = useState<Map<number, JSX.Element>>(
    new Map(),
  );

  const open = (modalProps: ModalContainerProps) => {
    const idx = new Date().getTime();

    const cmp = (
      <ModalContainer
        // @ts-ignore
        onClickOk={() => close(idx)}
        onClose={() => close(idx)}
        {...modalProps}
      />
    );

    setComponents((prev) => new Map(prev.set(idx, cmp)));

    return idx;
  };

  const close = (idx: number) => {
    setComponents((prev) => {
      prev.delete(idx);
      return new Map(prev);
    });
  };

  return (
    <ModalContext.Provider
      value={{
        open,
        close,
      }}
    >
      {children}
      {Array.from(components.values())}
    </ModalContext.Provider>
  );
};
