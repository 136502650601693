import { ChannelsAll } from 'store/dto/ChannelsAll';
import { api } from './api';

export interface ChannelAllRequest {
  Offset: number;
  Size: number;
}

export const channelsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    channelsAll: builder.query<ChannelsAll, ChannelAllRequest>({
      query: (params) => ({
        url: '/Channel/all',
        params,
      }),
    }),
  }),
});

export const { useChannelsAllQuery } = channelsApi;
