import { useCallback, useState } from 'react';
import { ViewMode, Gantt as GanttBase, Task } from 'gantt-task-react';
import { useGantt } from 'hooks/useGantt';
import { initTasks } from './mockData';
import { TaskListTable } from './TaskListTable';
import { TaskListHeader } from './TaskListHeader';

export const Gantt = () => {
  const [_, setTasks] = useState(initTasks());
  const { tasks, viewMode, editTask, editTaskDates } = useGantt();

  const [collapsed, setCollapsed] = useState<Array<string>>([]);

  // const [updateGantt] = useUpdateGanttMutation();

  // useEffect(() => {
  //   updateGantt({
  //     id: 1,
  //     parentId: null,
  //     typeId: 1,
  //     name: "testname",
  //     agent: "testagent2",
  //     startDate: "2023-07-22T11:43:47.165Z",
  //     endDate: "2023-09-22T11:43:47.165Z",
  //     note1: "note11",
  //     note2: "note22",
  //     note3: "note33"
  //   });
  // }, [updateGantt]);

  let columnWidth = 60;

  if (viewMode === ViewMode.Month) {
    columnWidth = 300;
  } else if (viewMode === ViewMode.Week) {
    columnWidth = 250;
  }

  // const handleTaskChange = (task: Task) => {
  //   let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
  //   if (task.project) {
  //     const [start, end] = getStartEndDateForProject(newTasks, task.project);
  //     const project =
  //       newTasks[newTasks.findIndex((t) => t.id === task.project)];
  //     if (
  //       project.start.getTime() !== start.getTime() ||
  //       project.end.getTime() !== end.getTime()
  //     ) {
  //       const changedProject = { ...project, start, end };
  //       newTasks = newTasks.map((t) =>
  //         t.id === task.project ? changedProject : t
  //       );
  //     }
  //   }
  //   setTasks(newTasks);
  // };

  // const handleProgressChange = async (task: Task) => {
  //   setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  //   console.log("On progress change Id:" + task.id);
  // };

  const handleDblClick = useCallback(
    (task: Task) => {
      editTask(task);
    },
    [editTask],
  );

  const handleTaskChange = useCallback(
    (task: Task) => {
      editTaskDates(task);
    },
    [editTaskDates],
  );

  const handleExpanderClick = (task: Task) => {
    setCollapsed((collapsed) => {
      if (collapsed.includes(task.id)) {
        return collapsed.filter((tid) => task.id !== tid);
      }
      return [...collapsed, task.id];
    });
  };

  const handleCreatedTask = (projectTask: Task) => {
    setCollapsed((collapsed) => {
      if (collapsed.includes(projectTask.id)) {
        return collapsed.filter((tid) => projectTask.id !== tid);
      }
      return collapsed;
    });
  };

  return tasks.length > 0 ? (
    <GanttBase
      locale="ru-RU"
      tasks={tasks.map((t) => ({
        ...t,
        hideChildren: collapsed.includes(t.id),
      }))}
      viewMode={viewMode}
      onDateChange={handleTaskChange}
      // onProgressChange={handleProgressChange}
      onDoubleClick={handleDblClick}
      onExpanderClick={handleExpanderClick}
      onCreatedTask={handleCreatedTask}
      listCellWidth="181px"
      columnWidth={columnWidth}
      TaskListHeader={TaskListHeader}
      TaskListTable={TaskListTable}
    />
  ) : null;
};
