import { FilialModel } from 'store/dto/FilialModel';
import { ServiceInfoCreateModel } from 'store/dto/ServiceInfoCreateModel';
import { CreateFilialFinanceModel } from 'store/dto/CreateFilialFinanceModel';
import { UpdateFilialFinanceModel } from 'store/dto/UpdateFilialFinanceModel';
import { UpdateServiceAmountModel } from 'store/dto/UpdateServiceAmountModel';
import { UpdateServiceInfoModel } from 'store/dto/UpdateServiceInfoModel';
import { api } from './api';

export interface FilialRequest {
  year: number;
  channelId?: number;
  pboTypeId?: number;
  city?: string;
}

export const filialsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getData: builder.query<FilialModel[], FilialRequest>({
      query: (params) => ({
        url: '/getData',
        params,
      }),
    }),
    getFederalData: builder.query<FilialModel[], FilialRequest>({
      query: (params) => ({
        url: '/getFederalData',
        params,
      }),
    }),
    createService: builder.mutation<void, ServiceInfoCreateModel>({
      query: (body) => ({
        url: '/createService',
        method: 'POST',
        body,
      }),
    }),
    createFilialFinance: builder.mutation<void, CreateFilialFinanceModel>({
      query: (body) => ({
        url: '/createFilialFinance',
        method: 'POST',
        body,
      }),
    }),
    updateFilialFinance: builder.mutation<void, UpdateFilialFinanceModel>({
      query: (body) => ({
        url: '/updateFilialFinance',
        method: 'PATCH',
        body,
      }),
    }),
    updateFilialFinanceNetSalesFact: builder.mutation<
      void,
      { id: number; netSalesFact: number | null }
    >({
      query: (body) => ({
        url: '/updateFilialFinanceNetSalesFact',
        method: 'PATCH',
        body,
      }),
    }),
    updateServiceAmount: builder.mutation<void, UpdateServiceAmountModel>({
      query: (body) => ({
        url: '/updateServiceAmount',
        method: 'PATCH',
        body,
      }),
    }),
    deleteService: builder.mutation<void, { id: number }>({
      query: (id) => ({
        url: `/deleteService/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteServiceRow: builder.mutation<void, Number[]>({
      query: (body) => ({
        url: `/deleteServiceArray`,
        method: 'DELETE',
        body,
      }),
    }),
    updateServiceInfo: builder.mutation<void, UpdateServiceInfoModel>({
      query: (body) => ({
        url: '/updateServiceInfo',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useGetDataQuery,
  useGetFederalDataQuery,
  useCreateServiceMutation,
  useCreateFilialFinanceMutation,
  useUpdateFilialFinanceMutation,
  useUpdateFilialFinanceNetSalesFactMutation,
  useUpdateServiceAmountMutation,
  useDeleteServiceMutation,
  useDeleteServiceRowMutation,
  useUpdateServiceInfoMutation,
} = filialsApi;
