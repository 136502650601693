import { FC, useCallback } from 'react';

import {
  TreeDataState,
  CustomTreeData,
  Column,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableFixedColumns,
  TableHeaderRow,
  VirtualTable,
} from 'dx-react-grid-bootstrap5';

import { SimpleCell } from 'components/table/SimpleCell';
import {
  ChannelTreeColumn,
  getChildRows,
} from 'components/table/ChannelTreeColumn';
import { useModal } from 'hooks/useModal';
import { EditableCell } from 'components/table/EditableCell';
import { Button, Stack } from 'react-bootstrap';
import {
  useCreateFilialFinanceMutation,
  useUpdateFilialFinanceMutation,
  useUpdateFilialFinanceNetSalesFactMutation,
} from 'store/services/filials';
import { FilialModel } from 'store/dto/FilialModel';
import { GetDataChannelModel } from 'store/dto/GetDataChannelModel';
import { FilialType } from 'lib/enums/FilialType';
import { FilialFinanceModel } from 'store/dto/FilialFinanceModel';
import { useGetDataFilter } from 'hooks/useGetDataFilter';
import { roundPercent } from 'lib/utils/number/roundPercent';
import { isNullOrUndefined } from 'lib/isNullOrUndefined';
import { numToRub, yup } from 'lib';
import { useSwitchableGetData } from 'hooks/useSwitchableGetData';
import styled from 'styled-components';
import { GridRootLayout } from 'layouts/GridRootLayouts';
import {
  selectRowsExpanded,
  setExpandedRows,
} from 'store/features/expandedRowsSlice';
import { useSelector } from 'store/store';
import { useDispatch } from 'react-redux';
import { NumberInput } from 'components/inputs/NumberInput';
import { cleanObject } from 'lib/cleanObject';

const HeaderCell = styled.div`
  font-weight: normal;
  text-align: center;
  height: 50px;
`;

const NoCellData: FC = () => (
  <div style={{ color: 'gray', paddingTop: 10 }}>Нет данных</div>
);

export const MonthTable: FC = () => {
  const { month, year, city, pboType } = useGetDataFilter();
  const dispatch = useDispatch();
  const modalHook = useModal();

  const {
    data = [],
    isFetching,
    isLoading,
    refetch,
  } = useSwitchableGetData(cleanObject({ year, city, pboTypeId: pboType }));

  const dataWithoutChannels = data.map((item) => {
    return {
      ...item,
      channels: [],
    };
  });

  const [createFilialFinance] = useCreateFilialFinanceMutation();
  const [updateFilialFinance] = useUpdateFilialFinanceMutation();
  const [updateFilialFinanceNetSalesFact] =
    useUpdateFilialFinanceNetSalesFactMutation();

  const rowsExpanded = useSelector(selectRowsExpanded);

  const formatFilialNameFromRow = (r: FilialModel) => {
    if (r.filialTypeId === FilialType.ПБО) {
      return r.filialCode
        ? `${r.filialCode}-${r.filialName}, ${r.filialCity}, ${r.filialAddress}`
        : `${r.filialName}, ${r.filialCity}, ${r.filialAddress}`;
    }

    return `${r.filialName} - ${r.filialManagerSurname || ''} ${
      r.filialManagerName || ''
    } ${r.filialManagerPatronymic || ''}`;
  };

  const formatChannelFinanceCellContent = (r: GetDataChannelModel) =>
    r.channelName;

  const columns: Column[] = [
    {
      name: 'filialName',
      title: (<HeaderCell>Каналы</HeaderCell>) as unknown as string,
      getCellValue: (r: any) =>
        (r.isChannel
          ? formatChannelFinanceCellContent
          : formatFilialNameFromRow)(r),
    },
    {
      name: 'netSalesPlan',
      title: (
        <HeaderCell>
          NetSales <div>план</div>
        </HeaderCell>
      ) as unknown as string,
    },
    {
      name: 'netSalesFact',
      title: (
        <HeaderCell>
          NetSales <div>факт</div>
        </HeaderCell>
      ) as unknown as string,
    },
    {
      name: 'netSalesPrognoz',
      title: (
        <HeaderCell>
          NetSales <div>прогноз</div>
        </HeaderCell>
      ) as unknown as string,
    },
    {
      name: 'marketingAmountPlan',
      title: (
        <HeaderCell>
          Плановый <div>маркет. бюджет</div>
        </HeaderCell>
      ) as unknown as string,
    },
    {
      name: 'marketingAmountFact',
      title: (
        <HeaderCell>
          Фактический <div>маркет. бюджет</div>
        </HeaderCell>
      ) as unknown as string,
    },
    {
      name: 'deficit',
      title: (
        <HeaderCell>
          Дефицит <div>Профицит</div>
        </HeaderCell>
      ) as unknown as string,
    },
  ];

  const tableColumnExtensions: Table.ColumnExtension[] = [
    { columnName: 'filialName', width: 500, align: 'center' },
    {
      columnName: 'marketingAmountPlan',
      align: 'center',
      wordWrapEnabled: true,
    },
    {
      columnName: 'marketingAmountFact',
      align: 'center',
      wordWrapEnabled: true,
    },
    { columnName: 'deficit', align: 'center', wordWrapEnabled: true },
    { columnName: 'netSalesPlan', align: 'center', wordWrapEnabled: true },
    { columnName: 'netSalesFact', align: 'center', wordWrapEnabled: true },
    { columnName: 'netSalesPrognoz', align: 'center', wordWrapEnabled: true },
  ];

  const handleDeleteNetSalesFact = useCallback(
    async (row: FilialModel, modalId: number) => {
      const filialFinance = row.filialFinance.find(
        (ff) => ff.month === month && ff.year === year,
      )!;
      try {
        await updateFilialFinanceNetSalesFact({
          id: filialFinance.id,
          netSalesFact: null,
        }).unwrap();
        refetch();
        modalHook.close(modalId);

        modalHook.open({
          okButton: true,
          variant: 'success',
          title: 'Удаление данных',
          bodyRenderer: () => (
            <span style={{ textAlign: 'center' }}>
              Данные NetSales факт из <br />
              <b>{formatFilialNameFromRow(row)}</b>
              <br />
              успешно удалены
            </span>
          ),
        });
      } catch (e) {
        modalHook.open({
          okButton: true,
          variant: 'error',
          title: 'Удаление данных',
          bodyRenderer: () => (
            <span style={{ textAlign: 'center' }}>
              Ошибка при удалении NetSales факт из
              <br />
              <b>{formatFilialNameFromRow(row)}</b>
            </span>
          ),
        });
      }
    },
    [month, year, modalHook, refetch, updateFilialFinanceNetSalesFact],
  );

  const netSalesPlanCellRenderer = (cellProps: any) => {
    const { row } = cellProps;
    let netSalesPlan: undefined | number;

    if (row.filialTypeId) {
      const ff = row.filialFinance.find(
        (ff: FilialFinanceModel) => ff.month === month,
      ) as FilialFinanceModel;
      if (ff && ff.netSalesPlan) {
        netSalesPlan = ff.netSalesPlan;
      }
    }

    if (row.isChannel) {
      return <SimpleCell {...cellProps} />;
    }

    if (row.isService) {
      return <SimpleCell {...cellProps} />;
    }

    return (
      <EditableCell
        {...cellProps}
        canEditable={row.filialTypeId === FilialType.ПБО}
        displayRenderer={() =>
          isNullOrUndefined(netSalesPlan) ? (
            <NoCellData />
          ) : (
            <div>{numToRub(netSalesPlan!)} р</div>
          )
        }
        initialValues={{ netSalesPlan }}
        validationSchema={yup.object().shape({
          netSalesPlan: yup
            .number()
            .required()
            .moreThan(0)
            .label('NetSales план'),
        })}
        onCommitedCanges={async ({ netSalesPlan }) => {
          try {
            const ff = row.filialFinance.find(
              (ff: FilialFinanceModel) => ff.month === month,
            );
            if (ff) {
              await updateFilialFinance({
                id: ff.id,
                netSalesPlan,
                netSalesFact: ff.netSalesFact,
                marketingPercentPlan: ff.marketingPercentPlan,
              }).unwrap();
            } else {
              await createFilialFinance({
                filialId: row.filialId,
                netSalesPlan,
                year,
                month,
              }).unwrap();
            }

            refetch();
          } catch (e) {
            console.error(e);
          }
        }}
        editorRenderer={({ column, register }) => (
          <div style={{ padding: '0 4px' }}>
            <Stack direction="horizontal" gap={2}>
              <NumberInput
                autoFocus
                size="sm"
                {...register(column.name)}
                style={{ height: 20, minHeight: 0, padding: '0 4px' }}
              />
              <div>р</div>
            </Stack>
          </div>
        )}
      />
    );
  };

  const netSalesFactCellRenderer = (cellProps: any) => {
    const { row } = cellProps;
    let netSalesFact = 0;
    let netSalesPlan = null;

    if (row.filialTypeId) {
      const ff = row.filialFinance.find(
        (ff: FilialFinanceModel) => ff.month === month,
      ) as FilialFinanceModel;
      netSalesPlan = ff?.netSalesPlan;

      if (ff && ff.netSalesFact) {
        netSalesFact = ff.netSalesFact;
      }
    }

    if (row.isChannel) {
      return <SimpleCell {...cellProps} />;
    }

    if (row.isService) {
      return <SimpleCell {...cellProps} />;
    }

    return (
      <EditableCell
        {...cellProps}
        canEditable={
          !isNullOrUndefined(netSalesPlan) &&
          row.filialTypeId === FilialType.ПБО
        }
        canDelete={
          !isNullOrUndefined(netSalesPlan) &&
          row.filialTypeId === FilialType.ПБО
        }
        displayRenderer={() =>
          isNullOrUndefined(netSalesFact) ? (
            <NoCellData />
          ) : (
            <div>{numToRub(netSalesFact!)} р</div>
          )
        }
        initialValues={{ netSalesFact }}
        validationSchema={yup.object().shape({
          netSalesFact: yup.number().required().label('NetSales факт'),
        })}
        onCommitedCanges={async ({ netSalesFact }) => {
          try {
            const ff = row.filialFinance.find(
              (ff: FilialFinanceModel) => ff.month === month,
            );
            if (ff) {
              await updateFilialFinanceNetSalesFact({
                id: ff.id,
                netSalesFact,
              }).unwrap();
            }

            refetch();
          } catch (e) {
            console.error(e);
          }
        }}
        onDelete={async () =>
          new Promise((resolve, reject) => {
            const modalId = modalHook.open({
              title: 'Удаление данных',
              bodyRenderer: () => (
                <Stack gap={3} style={{ justifyContent: 'center' }}>
                  <span style={{ textAlign: 'center' }}>
                    Вы уверены, что хотите удалить данные NetSales факт из
                    <br />
                    <b>{formatFilialNameFromRow(cellProps.row)}</b>?
                  </span>
                  <Stack
                    direction="horizontal"
                    gap={3}
                    style={{ justifyContent: 'center' }}
                  >
                    <Button
                      variant="outline-warning"
                      onClick={() =>
                        handleDeleteNetSalesFact(cellProps.row, modalId)
                          .then(resolve)
                          .catch(reject)
                      }
                    >
                      Удалить
                    </Button>
                    <Button
                      variant="outline-secondary"
                      onClick={() => modalHook.close(modalId)}
                    >
                      Отмена
                    </Button>
                  </Stack>
                </Stack>
              ),
              onClose: () => modalHook.close(modalId),
            });
          })
        }
        editorRenderer={({ column, register }) => (
          <div style={{ padding: '0 4px' }}>
            <Stack direction="horizontal" gap={2}>
              <NumberInput
                autoFocus
                size="sm"
                {...register(column.name)}
                style={{ height: 20, minHeight: 0, padding: '0 4px' }}
              />
              <div>р</div>
            </Stack>
          </div>
        )}
      />
    );
  };

  const netSalesPrognozCellRenderer = (cellProps: any) => {
    const { row } = cellProps;
    let netSalesPrognoz: undefined | number;

    if (row.filialTypeId) {
      const ff = row.filialFinance.find(
        (ff: FilialFinanceModel) => ff.month === month,
      ) as FilialFinanceModel;

      if (ff && ff.netSalesPrognoz) {
        netSalesPrognoz = ff.netSalesPrognoz;
      }
    }

    if (row.isChannel) {
      return <SimpleCell {...cellProps} />;
    }

    if (row.isService) {
      return <SimpleCell {...cellProps} />;
    }

    return (
      <SimpleCell {...cellProps}>
        {isNullOrUndefined(netSalesPrognoz) ? (
          <NoCellData />
        ) : (
          <div>{numToRub(netSalesPrognoz!)} р</div>
        )}
      </SimpleCell>
    );
  };

  const marketingAmountPlanCellRenderer = (cellProps: any) => {
    const { row } = cellProps;
    let marketingAmountPlan = 0;
    let marketingPercentPlan = 0;
    let netSalesPlan = null;

    if (row.filialTypeId) {
      const ff = row.filialFinance.find(
        (ff: FilialFinanceModel) => ff.month === month,
      ) as FilialFinanceModel;
      netSalesPlan = ff?.netSalesPlan;

      if (ff && ff.marketingAmountPlan) {
        marketingAmountPlan = ff.marketingAmountPlan;
      }
      if (ff && ff.marketingPercentPlan) {
        marketingPercentPlan = ff.marketingPercentPlan;
      }
    }

    if (row.isChannel) {
      return <SimpleCell {...cellProps} />;
    }

    if (row.isService) {
      return <SimpleCell {...cellProps} />;
    }

    return (
      <EditableCell
        {...cellProps}
        canEditable={
          !isNullOrUndefined(netSalesPlan) &&
          row.filialTypeId === FilialType.ПБО
        }
        displayRenderer={({ row }) => (
          <>
            <div>{numToRub(marketingAmountPlan)} р</div>
            <div>{roundPercent(marketingPercentPlan)} %</div>
          </>
        )}
        initialValues={{ marketingPercentPlan }}
        validationSchema={yup.object().shape({
          marketingPercentPlan: yup
            .number()
            .required()
            .min(0)
            .label('Процент планового маркет. бюджета'),
        })}
        onCommitedCanges={async ({ marketingPercentPlan }) => {
          try {
            const ff = row.filialFinance.find(
              (ff: FilialFinanceModel) => ff.month === month,
            );
            if (ff) {
              await updateFilialFinance({
                id: ff.id,
                netSalesPlan: ff.netSalesPlan,
                netSalesFact: ff.netSalesFact,
                marketingPercentPlan,
              }).unwrap();
            }

            refetch();
          } catch (e) {
            console.error(e);
          }
        }}
        editorRenderer={({ row, column, register }) => (
          <div style={{ padding: '0 4px' }}>
            <div>{numToRub(marketingAmountPlan)} р</div>
            <Stack direction="horizontal" gap={2}>
              <NumberInput
                autoFocus
                size="sm"
                {...register('marketingPercentPlan')}
                style={{ height: 20, minHeight: 0, padding: '0 4px' }}
              />
              <div>%</div>
            </Stack>
          </div>
        )}
      />
    );
  };

  const marketingAmountFactCellRenderer = (cellProps: any) => {
    const { row } = cellProps;
    let marketingAmountFact = 0;
    let marketingPercentFact = 0;
    let netSalesPlan = null;

    if (row.filialTypeId) {
      const ff = row.filialFinance.find(
        (ff: FilialFinanceModel) => ff.month === month,
      ) as FilialFinanceModel;
      netSalesPlan = ff?.netSalesPlan;

      if (ff && ff.marketingAmountFact) {
        marketingAmountFact = ff.marketingAmountFact;
      }
      if (ff && ff.marketingPercentFact) {
        marketingPercentFact = ff.marketingPercentFact;
      }
    }

    if (row.isChannel) {
      return <SimpleCell {...cellProps} />;
    }

    if (row.isService) {
      return <SimpleCell {...cellProps} />;
    }

    return (
      <EditableCell
        {...cellProps}
        canEditable={
          !isNullOrUndefined(netSalesPlan) &&
          row.filialTypeId === FilialType.ПБО &&
          false
        } // Пока нет возможности редактировать
        displayRenderer={() => (
          <>
            <div>{numToRub(marketingAmountFact)} р</div>
            <div>{roundPercent(marketingPercentFact)} %</div>
          </>
        )}
        initialValues={{ marketingAmountFact }}
        validationSchema={yup.object().shape({
          marketingAmountFact: yup
            .number()
            .required()
            .moreThan(0)
            .label('Фактический маркет. бюджет'),
        })}
        onCommitedCanges={({ marketingAmountFact }) => {
          console.log(marketingAmountFact);
          return Promise.resolve(marketingAmountFact);
        }}
        editorRenderer={({ row, column, register }) => (
          <div style={{ padding: '0 4px' }}>
            <Stack direction="horizontal" gap={2}>
              <NumberInput
                autoFocus
                size="sm"
                {...register('marketingAmountFact')}
                style={{ height: 20, minHeight: 0, padding: '0 4px' }}
              />
              <div>р</div>
            </Stack>
            <div>{roundPercent(marketingPercentFact || 0)} %</div>
          </div>
        )}
      />
    );
  };

  const deficitCellRenderer = (cellProps: any) => {
    const { row } = cellProps;
    let deficit: undefined | number;

    if (row.filialTypeId) {
      const ff = row.filialFinance.find(
        (ff: FilialFinanceModel) => ff.month === month,
      ) as FilialFinanceModel;
      if (ff && ff.deficit) {
        deficit = ff.deficit;
      }
    }

    if (row.isChannel) {
      return <SimpleCell {...cellProps} />;
    }

    if (row.isService) {
      return <SimpleCell {...cellProps} />;
    }

    return (
      <SimpleCell {...cellProps}>
        {isNullOrUndefined(deficit) ? (
          <NoCellData />
        ) : (
          <div>{numToRub(deficit!)} р</div>
        )}
      </SimpleCell>
    );
  };

  const onExpandedRowIdsChange = useCallback(
    (expandedRowIds: (string | number)[]) => {
      dispatch(setExpandedRows([...expandedRowIds]));
    },
    [dispatch],
  );

  const defaultExpandedRowIds = Object.values(rowsExpanded).filter((x) =>
    ['string', 'number'].includes(typeof x),
  );

  return (
    <Grid
      rows={dataWithoutChannels}
      columns={columns}
      getRowId={(row: any) => row.filialId}
      rootComponent={GridRootLayout}
    >
      <TreeDataState
        expandedRowIds={defaultExpandedRowIds}
        onExpandedRowIdsChange={onExpandedRowIdsChange}
      />
      <CustomTreeData getChildRows={getChildRows} />
      <VirtualTable
        columnExtensions={tableColumnExtensions}
        cellComponent={(cellProps) => {
          switch (cellProps.column.name) {
            case 'netSalesPlan':
              return netSalesPlanCellRenderer(cellProps);
            case 'netSalesFact':
              return netSalesFactCellRenderer(cellProps);
            case 'netSalesPrognoz':
              return netSalesPrognozCellRenderer(cellProps);
            case 'marketingAmountPlan':
              return marketingAmountPlanCellRenderer(cellProps);
            case 'marketingAmountFact':
              return marketingAmountFactCellRenderer(cellProps);
            case 'deficit':
              return deficitCellRenderer(cellProps);
            default:
              return <SimpleCell {...cellProps} />;
          }
        }}
      />
      <TableHeaderRow />
      <ChannelTreeColumn for="filialName" />
      <TableFixedColumns leftColumns={['filialName']} />
    </Grid>
  );
};
