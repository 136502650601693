import { api } from './api';

export const citiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    cities: builder.query<string[], void>({
      query: () => ({
        url: '/getCities',
      }),
    }),
  }),
});

export const { useCitiesQuery } = citiesApi;
