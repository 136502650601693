import { GantInfoModel } from 'store/dto/GantInfoModel';
import { GantInfoCreateModel } from 'store/dto/GantInfoCreateModel';
import { GantInfoUpdateModel } from 'store/dto/GantInfoUpdateModel';
import {
  CreateGuestLinkModel,
  TrustLinkModel,
} from 'store/dto/CreateGuestLinkModel';
import { api } from './api';

export const ganttApi = api.injectEndpoints({
  endpoints: (builder) => ({
    ganttInfo: builder.query<Array<GantInfoModel>, boolean | void>({
      query: (isGuest) => ({
        url: isGuest ? '/getGantInfoGuest' : '/getGantInfo',
      }),
    }),
    createGantt: builder.mutation<void, GantInfoCreateModel>({
      query: (body) => ({
        url: '/createGantInfo',
        method: 'POST',
        body,
      }),
    }),
    updateGantt: builder.mutation<void, GantInfoUpdateModel>({
      query: ({ id, ...updateInfo }) => ({
        url: `/updateGantInfo/${id}`,
        method: 'PATCH',
        body: updateInfo,
      }),
    }),
    deleteGantt: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `/deleteGantInfo/${id}`,
        method: 'DELETE',
      }),
    }),
    createGuestLink: builder.mutation<TrustLinkModel, CreateGuestLinkModel>({
      query: (createGuestInfo) => ({
        url: `/createGuestLink/`,
        method: 'POST',
        body: createGuestInfo,
      }),
    }),
  }),
});

export const {
  useGanttInfoQuery,
  useCreateGanttMutation,
  useUpdateGanttMutation,
  useDeleteGanttMutation,
  useCreateGuestLinkMutation,
} = ganttApi;
