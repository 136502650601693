import { css } from 'styled-components';

export const cardStyle = css`
  .card {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border: 0;
    width: 100%;

    &-header {
      padding-block: ${({ theme }) => theme.spacing(1)};
      padding-inline: ${({ theme }) => theme.spacing(4)};
      background-color: ${({ theme }) => theme.palette.green};
      border-bottom: none;
    }

    &-body,
    &-footer {
      padding-block: ${({ theme }) => theme.spacing(2)};
      padding-inline: ${({ theme }) => theme.spacing(4)};
    }
  }
`;
