import { FC } from 'react';
import Form from 'react-bootstrap/Form';
import { timeToInt, timeFromInt } from 'time-number';
import { FormSelectProps } from 'react-bootstrap';

type Props = FormSelectProps & {
  end?: string;
  format?: 12 | 24;
  initialValue?: string;
  start?: string;
  step?: number;
  value?: any;
};

export const TimeInput: FC<Props> = ({
  end = '23:59',
  format = 24,
  initialValue = '',
  start = '00:00',
  step = 60,
  value = null,
  ...rest
}) => {
  const generateFormattedTime = (time: any) => {
    const ret = timeFromInt(time, false);

    if (format === 24) {
      return ret;
    }

    const found = ret.match(/^(\d+):/)!;
    const hour = parseInt(found[1], 10);

    if (hour === 0) {
      return `${ret.replace(/^\d+/, '12')} AM`;
    }

    if (hour < 12) {
      return `${ret} AM`;
    }

    if (hour === 12) {
      return `${ret} PM`;
    }

    const newHour = hour < 22 ? `0${hour - 12}` : (hour - 12).toString();

    return `${ret.replace(/^\d+/, newHour)} PM`;
  };

  const generateTimeRange = () => {
    const times = [];
    const iend = timeToInt(end, false);

    for (let i = timeToInt(start, false); i <= iend; i += step * 60) {
      times.push(i);
    }

    return times;
  };

  const listTimeOptions = () =>
    generateTimeRange().map((unformattedTime) => {
      const formattedTime = generateFormattedTime(unformattedTime);

      return {
        key: unformattedTime,
        val: formattedTime,
      };
    });

  const timeOptions = listTimeOptions();
  const optionWidgets = [{ key: 'default', val: '' }, ...timeOptions].map(
    ({ key, val }) => (
      <option key={key} value={val}>
        {val}
      </option>
    ),
  );

  return (
    <Form.Select value={value || initialValue} {...rest}>
      {optionWidgets}
    </Form.Select>
  );
};
