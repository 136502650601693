import { FC } from 'react';
import styled from 'styled-components';

export const TaskListHeader: FC<{
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
}> = ({ headerHeight, fontFamily, fontSize, rowWidth }) => (
  <GanttTable
    style={{
      fontFamily,
      fontSize,
    }}
  >
    <GanttTableHeader style={{ height: headerHeight - 2 }}>
      <GanttTableHeaderItem
        style={{
          minWidth: rowWidth,
        }}
      >
        &nbsp;Действие
      </GanttTableHeaderItem>
      <GanttTableHeaderSeparator
        style={{
          height: headerHeight * 0.5,
          marginTop: headerHeight * 0.2,
        }}
      />
      <GanttTableHeaderItem
        style={{
          minWidth: 141,
          maxWidth: 141,
        }}
      >
        &nbsp;Период
      </GanttTableHeaderItem>
      <GanttTableHeaderSeparator
        style={{
          height: headerHeight * 0.5,
          marginTop: headerHeight * 0.25,
        }}
      />
      <GanttTableHeaderItem
        style={{
          minWidth: 143,
        }}
      >
        &nbsp;Ответственный
      </GanttTableHeaderItem>
    </GanttTableHeader>
  </GanttTable>
);

const GanttTable = styled.div`
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-top: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
`;

const GanttTableHeader = styled.div`
  display: table-row;
  list-style: none;
`;

const GanttTableHeaderSeparator = styled.div`
  border-right: 1px solid rgb(196, 196, 196);
  opacity: 1;
  margin-left: -2px;
`;

const GanttTableHeaderItem = styled.div`
  display: table-cell;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
`;
