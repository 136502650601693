import { Table } from '@devexpress/dx-react-grid';
import classNames from 'clsx';
import { ContextMenuContainer } from 'components/containers/ContextMenuContainer';
import { FieldInputProps, useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getMonthText } from 'lib/getMonthText';

type FormType = ReturnType<typeof useFormik>;

type CellProps = Table.DataCellProps & {
  canEditable?: boolean;
  canDelete?: boolean;
  displayRenderer: (cellProps: CellProps) => JSX.Element;
  editorRenderer: (
    cellProps: CellProps & {
      form: FormType;
      register: (fieldName: string) => FieldInputProps<any>;
    },
  ) => JSX.Element;
  initialValues: any;
  validationSchema?: any;
  onCommitedCanges: (values: any, form: FormType, row: any) => Promise<void>;
  onDelete: (cellProps: CellProps) => Promise<void>;
  onDeleteRow?: (cellProps: CellProps) => Promise<void>;
  forwardedRef: any;
  className?: string;
};

const StyledCell = styled.td`
  padding: 4px;
  position: relative;
  overflow: initial;
`;

const StyledInnerCell = styled.div`
  border: 1px solid gray;
  border-radius: 3px;
  text-align: center;
  min-height: 50px;
  position: relative;
  background-color: #f5f5f5;

  &.is-editing,
  &.visible-ctx-menu {
    outline: 2px solid #4ca36e;
  }

  &.visible-error {
    outline: 2px solid red;
  }
`;

const StyledErrors = styled.div`
  top: 100%;
  min-width: 100%;
  position: absolute;
  background-color: #b60000;
  color: white;
  padding: 4px;
  border-radius: 4px;
  z-index: 9999999;
`;

const StyledPending = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContextMenuBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;

  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  padding: 3px 6px;
  background-color: white;
  min-height: 50px;
  text-align: left;
  white-space: nowrap;
`;

const StyledDeleteBtn = styled.button`
  border: none;
  background-color: white;
  padding-left: 0;
  padding-bottom: 3px;
  font-size: 15px;
  &:hover {
    color: #b60000;
  }
`;

const StyledCancelBtn = styled(StyledDeleteBtn)`
  &:hover {
    color: #4ca36e;
  }
`;

export const EditableCell: React.ComponentType<CellProps> = (props) => {
  const {
    column,
    value,
    children,
    tableRow,
    tableColumn,
    row,
    forwardedRef,
    className,
    displayRenderer,
    editorRenderer,
    initialValues,
    validationSchema,
    onCommitedCanges,
    onDelete,
    onDeleteRow,
    canEditable: isEditable = true,
    canDelete = false,
    ...restProps
  } = props;

  const [isEditableNow, setIsEditableNow] = useState(false);
  const [isFocusedAnyField, setIsFocusedAnyField] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [visibleContextMenu, setVisibleContextMenu] = useState(false);
  const [isDeletePending, setIsDeletePending] = useState(false);

  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (form.dirty) {
        setIsPending(true);

        onCommitedCanges(values, form, row)
          .then(() => {
            setIsEditableNow(false);
            setIsPending(false);
          })
          .catch(() => {
            setIsPending(false);
          });
      }
    },
  });

  const valuesAreInitial = form.values === initialValues;
  const errors = Object.values(form.errors);
  const hasErrors = !valuesAreInitial && errors.length > 0;

  const handleDeletePeriod = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      setIsDeletePending(true);
      onDelete(props)
        .then(() => {
          setIsDeletePending(false);
          setVisibleContextMenu(false);
        })
        .catch(() => {
          setIsDeletePending(false);
          setVisibleContextMenu(false);
        });
    },
    [setIsDeletePending, onDelete, setVisibleContextMenu],
  );

  const handleDeleteRow = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      setIsDeletePending(true);
      if (onDeleteRow) {
        onDeleteRow(props)
          .then(() => {
            setIsDeletePending(false);
            setVisibleContextMenu(false);
          })
          .catch(() => {
            setIsDeletePending(false);
            setVisibleContextMenu(false);
          });
      }
    },
    [setIsDeletePending, onDeleteRow, setVisibleContextMenu],
  );

  useEffect(() => {
    let timeoutId: any;

    if (isEditableNow) {
      timeoutId = setTimeout(() => {
        if (!isFocusedAnyField) {
          setIsEditableNow(false);
          form.handleSubmit();
          form.setValues(initialValues);
        }
      }, 100);
    } else {
      clearTimeout(timeoutId);
    }

    return () => clearTimeout(timeoutId);
  }, [form, isEditableNow]);

  const register = (fieldName: string) => {
    const fieldProps = form.getFieldProps(fieldName);

    return {
      ...fieldProps,
      onFocus: (e: any) => {
        setIsFocusedAnyField(true);
        e.target.select();
      },
      onBlur: (e: any) => {
        fieldProps.onBlur(e);
        setIsFocusedAnyField(false);
      },
    };
  };

  const onClickOutsideCtxMenu = useCallback(() => {
    setVisibleContextMenu(false);
  }, [setVisibleContextMenu]);

  const onContextMenu = useCallback(() => {
    setVisibleContextMenu(false);
  }, [setVisibleContextMenu]);

  const mounthTitle = getMonthText(column) ?? '';

  return (
    <StyledCell
      className={classNames(
        {
          'dx-g-bs5-table-cell': true,
          'text-nowrap': false,
          'text-right': tableColumn && tableColumn.align === 'right',
          'text-center': tableColumn && tableColumn.align === 'center',
        },
        className,
      )}
      ref={forwardedRef}
      {...restProps}
    >
      <StyledInnerCell
        className={classNames({
          'is-editing': isEditableNow,
          'visible-ctx-menu': visibleContextMenu,
          'visible-error': hasErrors,
        })}
        onClick={() => isEditable === true && setIsEditableNow(true)}
        onContextMenu={(e) => {
          if (e.type === 'contextmenu') {
            e.preventDefault();

            if (canDelete) {
              const tid = setTimeout(() => {
                setVisibleContextMenu(true);
                clearTimeout(tid);
              }, 100);
            }
          }
        }}
      >
        {isEditableNow
          ? editorRenderer({ ...props, form, register })
          : displayRenderer(props)}
        {isPending && <StyledPending>Отправка...</StyledPending>}
        {hasErrors && (
          <StyledErrors>
            {errors.map((txt) => (
              <div style={{ whiteSpace: 'nowrap' }}>{txt as string}</div>
            ))}
          </StyledErrors>
        )}
      </StyledInnerCell>
      {canDelete && visibleContextMenu && (
        <ContextMenuContainer
          onClickOutside={onClickOutsideCtxMenu}
          onContextMenu={onContextMenu}
        >
          <ContextMenuBox>
            <div>
              <StyledDeleteBtn
                onClick={handleDeletePeriod}
                disabled={isDeletePending}
              >
                {isDeletePending
                  ? 'Обработка запроса...'
                  : `Удалить данные за ${mounthTitle}`}
              </StyledDeleteBtn>
            </div>
            <div>
              {onDeleteRow && (
                <StyledDeleteBtn
                  onClick={handleDeleteRow}
                  disabled={isDeletePending}
                >
                  {isDeletePending
                    ? 'Обработка запроса...'
                    : 'Удалить данные за весь год'}
                </StyledDeleteBtn>
              )}
            </div>
            <div>
              <StyledCancelBtn>Отмена</StyledCancelBtn>
            </div>
          </ContextMenuBox>
        </ContextMenuContainer>
      )}
    </StyledCell>
  );
};
