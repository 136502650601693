import { FC } from 'react';

import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { AppHeader } from 'components';

const Container = styled.div`
  height: 100vh;
`;

const AuthLayout: FC = () => (
  <Container>
    <AppHeader />

    <Outlet />
  </Container>
);

export default AuthLayout;
