import { createGlobalStyle } from 'styled-components';
import { styleOverrides } from './overrides';

const globalStyle = createGlobalStyle`
  ${styleOverrides}

  * {
    font-family: ${({ theme }) => theme.fonts.arial};
  }

  body {
    background-color: ${({ theme }) => theme.palette.white};
  }

  hr {
    margin: ${({ theme }) => theme.spacing(2)} 0;
    border-color: ${({ theme }) => theme.palette.dgray};
  }

  .dx-rg-bs5-table-header-title {
    display: block;
    width: 100%;
  }

  .dx-g-bs5-fixed-header {
    background-color: #FFFFFF;
  }

  // fix row нового проекта
  .table>:not(caption)>*>* {
    --bs-table-bg: 'rgba(255,255,255,0)'
  }
  .table>:not(caption)>*>.dx-g-bs5-fixed-cell {
    --bs-table-bg: ${({ theme }) => theme.palette.white};
  }

svg *:focus {
  outline: none;
}

  input[type=checkbox].form-check-input:checked {
    background-color: rgb(76, 163, 110);
    border-color: rgb(76, 163, 110);
}
`;

export default globalStyle;
