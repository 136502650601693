import { Form, FormControlProps } from 'react-bootstrap';
import { FieldHelperProps, FieldMetaProps, useFormik } from 'formik';
import { FC, forwardRef, ReactNode, Ref } from 'react';

export const checkRegister = (
  name: string,
  form: ReturnType<typeof useFormik<any>>,
) => ({
  ...form.getFieldProps(name),
  ...form.getFieldMeta(name),
  ...form.getFieldHelpers(name),
  checked: form.values[name],
  onChange: (e: any) => form.setFieldValue(name, e.target.checked),
  setFieldValue: form.setFieldValue,
});

export type FormFieldCheckProps = FormControlProps &
  Partial<FieldMetaProps<any>> &
  Partial<FieldHelperProps<any>> & {
    name: string;
    label: ReactNode;
    startIcon?: ReactNode;
    floatingError?: boolean;
    loading?: boolean;
    autofocus?: boolean;
    ref?: Ref<HTMLInputElement | null>;
    maxLength?: number;
    fullHeight?: boolean;
    type?: 'radio' | 'checkbox';
    setFieldValue: any;
  };

export const FormFieldCheck: FC<FormFieldCheckProps> = forwardRef<
  HTMLInputElement | null,
  FormFieldCheckProps
>(
  (
    {
      name,
      label,
      startIcon,
      disabled = false,
      loading = false,
      error = '',
      floatingError = false,
      autofocus = false,
      type,
      value,
      maxLength,
      onChange,
      fullHeight = false,
      setFieldValue,
      ...otherProps
    },
    ref,
  ) => {
    return (
      <Form.Check type={type} id={`check-api-${type}`}>
        <Form.Check.Input
          type={type}
          checked={value}
          onChange={(e) => setFieldValue(name, e.target.checked)}
        />
        <Form.Check.Label>{label}</Form.Check.Label>
      </Form.Check>
    );
  },
);
