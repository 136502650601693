import { FlexContainer } from 'components/containers';
import { FormikAsyncSelect } from 'components/formik';
import { selectOptionValidationSchema } from 'components/formik/validationSchemas';
import { Typography } from 'components/typography';
import { useFormik } from 'formik';
import { yup } from 'lib';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { themeProvider } from 'theme';
import { GetDataServiceModel } from 'store/dto/GetDataServiceModel';
import { useChannelsAllQuery } from 'store/services/channels';
import { useToolsAllQuery } from 'store/services/tools';
import { useUpdateServiceInfoMutation } from 'store/services/filials';
import { useModal } from 'hooks/useModal';
import { FormField, register } from 'components/inputs/FormField';
import { ToolModel } from 'store/dto/ToolModel';
import { SelectOptionsType } from 'components/inputs/Selects/types';

export interface EditInstrumentFormProps {
  service: GetDataServiceModel;
  onClose: () => void;
  onSubmitted: () => void;
  onCancel?: () => void;
}

const validationSchema = yup.object().shape({
  tool: selectOptionValidationSchema,
  contractorName: yup.string().required(),
  address: yup.string().required(),
  description: yup.string().required(),
});

interface FormFields {
  tool: {
    label: string;
    value: string;
  } | null;
  contractorName: string;
  address: string;
  description: string;
}

type chanelTreeType = Record<number, ToolModel[]>;

const EditInstrumentForm: React.FC<EditInstrumentFormProps> = ({
  service,
  onSubmitted,
  onCancel,
  onClose,
}) => {
  const [updateServiceInfo] = useUpdateServiceInfoMutation();
  const { data: channelsAllData } = useChannelsAllQuery({
    Offset: 0,
    Size: 100,
  });
  const { data: toolsAllData } = useToolsAllQuery({ Offset: 0, Size: 100 });
  const modalHook = useModal();

  const [tools, setTools] = useState<ToolModel[]>([]);
  const [chanelTree, setChanelTree] = useState<chanelTreeType>({});

  const chanelId = service?.channelRow?.id;

  useEffect(() => {
    const chanelTree: chanelTreeType = {};
    if (toolsAllData?.data && channelsAllData?.data) {
      toolsAllData.data.forEach((tool: ToolModel) => {
        if (tool?.channelIds) {
          tool.channelIds.forEach((channelId: number) => {
            chanelTree[channelId] = chanelTree[channelId] ?? [];
            chanelTree[channelId].push(tool);
          });
        }
      });
    }
    setChanelTree(chanelTree);

    setTools(chanelTree[Number(chanelId)]);
  }, [toolsAllData, channelsAllData]);

  const getToolOptions = (): SelectOptionsType[] => {
    if (tools && tools.length) {
      return tools.map(({ name, id }) => ({ label: name, value: String(id) }));
    }
    return [];
  };

  const formik = useFormik<FormFields>({
    initialValues: {
      tool: {
        value: service.toolid.toString(),
        label: service.toolName!,
      },
      contractorName: service.contractorName!,
      address: service.address!,
      description: service.description!,
    },
    validationSchema,
    onSubmit: () => {},
    isInitialValid: false,
  });

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }

    onClose();
  }, [formik.values, onCancel, onClose]);

  const handleSubmit = useCallback(async () => {
    try {
      await updateServiceInfo({
        id: service.id,
        toolId: Number.parseInt(formik.values.tool!.value, 10),
        address: formik.values.address,
        contractorName: formik.values.contractorName,
        description: formik.values.description,
      }).unwrap();

      onSubmitted();
      onClose();

      return null;
    } catch (e) {
      modalHook.open({
        okButton: true,
        variant: 'error',
        title: 'Редактирование инструмента',
        bodyRenderer: () => <span>Ошибка при редактирование инструмента</span>,
      });
    }
  }, [updateServiceInfo, formik.values, onClose, onSubmitted]);

  const filial = service.channelRow!.filialRow!;

  return (
    <FlexContainer
      flexDirection="column"
      rowGap={themeProvider.spacing(2)}
      width={500}
    >
      <Typography
        variant="body1"
        style={{ width: 'fit-content', margin: '0 auto' }}
      >{`${filial.filialCode}-${filial.filialName}, ${filial.filialCity}, ${filial.filialAddress}`}</Typography>
      <Typography variant="body1">
        Канал: {service.channelRow!.channelName}
      </Typography>
      <FormikAsyncSelect
        formik={formik}
        defaultOptions={getToolOptions()}
        // onInputChange={promiseOptions}
        label="Инструмент"
        fieldName="tool"
      />
      <FormField label="Контрагент" {...register('contractorName', formik)} />
      <FormField
        type="textarea"
        label="Месторасположение"
        {...register('address', formik)}
        fullHeight
      />
      <FormField
        type="textarea"
        label="Описание"
        {...register('description', formik)}
        style={{ minHeight: themeProvider.spacing(14) }}
      />
      <FlexContainer justifyContent="end" columnGap={themeProvider.spacing(2)}>
        <Button onClick={handleCancel} variant="outline-light">
          Отмена
        </Button>
        <Button
          onClick={handleSubmit}
          variant="light"
          disabled={!formik.isValid}
        >
          Сохранить
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default EditInstrumentForm;
