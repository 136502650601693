import { css } from 'styled-components';

export const buttonStyle = css`
  .btn {
    --bs-btn-padding-x: ${({ theme }) => theme.spacing(5)};
    --bs-btn-padding-y: ${({ theme }) => theme.spacing(0.5)};

    display: inline-flex;
    align-items: center;
    column-gap: ${({ theme }) => theme.spacing(1)};
    margin: 0;
    border-radius: 4px;

    &:disabled {
      color: ${({ theme }) => theme.palette.dgray};
      border-color: ${({ theme }) => theme.palette.gray};

      > svg {
        circle,
        path {
          stroke: ${({ theme }) => theme.palette.gray};
        }
      }
    }

    /* Variants */
    &-outline-primary {
      border-color: ${({ theme }) => theme.palette.green};
      color: ${({ theme }) => theme.palette.green};

      &:hover,
      &:not(.btn-check) + .btn:active,
      &.btn:first-child:active {
        background-color: ${({ theme }) => theme.palette.green};
        border-color: ${({ theme }) => theme.palette.green};
      }
    }

    &-primary {
      --bs-btn-color: ${({ theme }) => theme.palette.white};
      --bs-btn-bg: ${({ theme }) => theme.palette.green};
      --bs-btn-border-color: ${({ theme }) => theme.palette.green};
      --bs-btn-hover-bg: ${({ theme }) => theme.palette.green};
      --bs-btn-hover-border-color: ${({ theme }) => theme.palette.green};
      --bs-btn-active-bg: ${({ theme }) => theme.palette.green};
      --bs-btn-disabled-bg: ${({ theme }) => theme.palette.gray};
    }

    &-light {
      --bs-btn-color: ${({ theme }) => theme.palette.white};
      --bs-btn-hover-color: ${({ theme }) => theme.palette.white};
      --bs-btn-active-color: ${({ theme }) => theme.palette.white};
      --bs-btn-bg: ${({ theme }) => theme.palette.lgreen};
      --bs-btn-border-color: ${({ theme }) => theme.palette.lgreen};
      --bs-btn-hover-bg: ${({ theme }) => theme.palette.lgreen};
      --bs-btn-hover-border-color: ${({ theme }) => theme.palette.lgreen};
      --bs-btn-disabled-bg: ${({ theme }) => theme.palette.gray};
    }

    &-outline-light {
      border-color: ${({ theme }) => theme.palette.lgreen};
      color: ${({ theme }) => theme.palette.lgreen};
      --bs-btn-hover-color: ${({ theme }) => theme.palette.white};
      --bs-btn-active-color: ${({ theme }) => theme.palette.white};

      &:hover,
      &:not(.btn-check) + .btn:active,
      &.btn:first-child:active {
        background-color: ${({ theme }) => theme.palette.lgreen};
        border-color: ${({ theme }) => theme.palette.lgreen};
      }
    }

    /* Sizes */
    &-sm {
      height: 32px;
    }

    &-outline-warning {
      --bs-btn-color: ${({ theme }) => theme.palette.orange15};
      --bs-btn-border-color: ${({ theme }) => theme.palette.orange15};
      --bs-btn-hover-color: #000;
      --bs-btn-hover-bg: ${({ theme }) => theme.palette.orange15};
      --bs-btn-hover-border-color: ${({ theme }) => theme.palette.orange15};
      --bs-btn-focus-shadow-rgb: 255, 193, 7;
      --bs-btn-active-color: #000;
      --bs-btn-active-bg: ${({ theme }) => theme.palette.orange15};
      --bs-btn-active-border-color: ${({ theme }) => theme.palette.orange15};
      --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      --bs-btn-disabled-color: ${({ theme }) => theme.palette.orange15};
      --bs-btn-disabled-bg: transparent;
      --bs-btn-disabled-border-color: ${({ theme }) => theme.palette.orange15};
      --bs-gradient: none;
    }
  }
`;
