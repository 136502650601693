import styled from 'styled-components';
import { TypographyVariants } from 'theme';

export const TextComponent = styled.p<{
  variant: TypographyVariants;
  color: string;
}>`
  color: ${({ color }) => color};
  margin: 0;
  font-size: ${({ theme, variant }) => theme.typography[variant].fontSize};
  font-weight: ${({ theme, variant }) => theme.typography[variant].fontWeight};
  line-height: ${({ theme, variant }) => theme.typography[variant].lineHeight};
`;
