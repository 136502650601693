import { ComponentElement, FC, memo, useCallback, useContext } from 'react';
import { TableContext } from 'pages/HomePage/HomePage';
import {
  Template,
  Getter,
  Plugin,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import { Table } from 'dx-react-grid-bootstrap5';
import { TABLE_DATA_TYPE } from '@devexpress/dx-grid-core';
import { Stack } from 'react-bootstrap';
import ServiceAdd from 'components/icons/ServiceAdd';
import { FilialType } from 'lib/enums/FilialType';
import { FilialModel } from 'store/dto/FilialModel';
import { GetDataChannelModel } from 'store/dto/GetDataChannelModel';
import { GetDataServiceModel } from 'store/dto/GetDataServiceModel';
import { useModal } from 'hooks/useModal';
import AddInstrumentForm from 'components/common/Instruments/AddInstrumentForm';
import { useGetDataFilter } from 'hooks/useGetDataFilter';
import EditInstrumentForm from 'components/common/Instruments/EditInstrumentForm';
import { useSwitchableGetData } from 'hooks/useSwitchableGetData';
import { cleanObject } from 'lib/cleanObject';
import { ChannelTreeNode, ChannelTreeNodeTool } from './ChannelTreeNode';
import { ServiceFinanceTreeCell } from './ServiceFinanceTreeCell';

type Props = {
  for: string;
};

const ChannelTreeColumnBase: FC<Props> = ({ for: forColumnName }) => {
  const modalHook = useModal();
  const { year, city, pboType, showFederalData } = useGetDataFilter();
  const { refetch } = useSwitchableGetData(
    cleanObject({ year, city, pboTypeId: pboType }),
  );
  const { tableView } = useContext(TableContext);
  const isYearTable = tableView === 'year_table';

  const onClickAddInstrument = useCallback(
    (row: any) => {
      const modalId = modalHook.open({
        title: 'Добавление инструмента',
        bodyRenderer: () => (
          <AddInstrumentForm
            parentFilial={row}
            onClose={() => modalHook.close(modalId)}
            onSubmitted={() => {
              refetch();
            }}
          />
        ),
        onClose: () => modalHook.close(modalId),
      });
    },
    [modalHook, refetch],
  );

  const onEditService = useCallback(
    (channel: GetDataChannelModel, service: GetDataServiceModel) => {
      const modalId = modalHook.open({
        title: 'Редактирование инструмента',
        bodyRenderer: () => (
          <EditInstrumentForm
            service={service}
            onClose={() => modalHook.close(modalId)}
            onSubmitted={() => {
              refetch();

              modalHook.open({
                okButton: true,
                variant: 'success',
                title: 'Редактирование инструмента',
                bodyRenderer: () => (
                  <span>
                    Инструмент по каналу {service.channelRow?.channelName}{' '}
                    успешно изменен!
                  </span>
                ),
              });
            }}
          />
        ),
        onClose: () => modalHook.close(modalId),
      });
    },
    [modalHook, refetch],
  );

  return (
    <Plugin
      name="TableTreeColumn"
      dependencies={[
        { name: 'DataTypeProvider', optional: true },
        { name: 'TreeDataState' },
        { name: 'Table' },
        { name: 'TableHeaderRow', optional: true },
      ]}
      key={forColumnName}
    >
      <Getter name="tableTreeColumnName" value={forColumnName} />
      <Template
        name="tableCell"
        predicate={({ tableRow, tableColumn }: any) =>
          isTreeTableCell(tableRow, tableColumn, forColumnName)
        }
      >
        {(params: any) => (
          <TemplateConnector>
            {(
              {
                getCollapsedRows,
                expandedRowIds = [],
                selection,
                isTreeRowLeaf,
                getTreeRowLevel,
                getCellValue,
              },
              { toggleRowExpanded, toggleSelection },
            ) => {
              const { row, rowId } = params.tableRow;
              const columnName = params.tableColumn.column!.name;
              const value = getCellValue(row, columnName);
              const collapsedRows = getCollapsedRows(row);
              const visible = collapsedRows
                ? !!collapsedRows.length
                : !isTreeRowLeaf(row);
              const expanded = expandedRowIds.indexOf(rowId) > -1;
              const onToggle = () => toggleRowExpanded({ rowId });
              const onAddService = () => onClickAddInstrument(row);
              const isПБО = row.filialTypeId === FilialType.ПБО;

              const getTool = () => {
                if (
                  isYearTable &&
                  (isПБО ||
                    (showFederalData && !row.isChannel && !row.isService))
                ) {
                  return (
                    <ChannelTreeNodeTool
                      onClick={onAddService}
                      icon={<ServiceAdd />}
                      key="key"
                    />
                  );
                }
                return null as unknown as ComponentElement<any, any>;
              };

              return (
                <TemplatePlaceholder
                  name="valueFormatter"
                  params={{
                    value,
                    row,
                    column: params.tableColumn.column,
                  }}
                >
                  {(content) => (
                    <Table.Cell
                      {...params}
                      className={
                        row.isService
                          ? 'border-end dx-g-bs5-fixed-cell'
                          : params.className
                      }
                      row={row}
                      column={params.tableColumn.column!}
                      value={value}
                      style={{
                        overflow: 'initial',
                        ...params.style,
                        position: row.isService ? 'sticky' : undefined,
                      }}
                    >
                      <Stack direction="horizontal" gap={3}>
                        <Indent level={getTreeRowLevel(row)} />
                        {row.isService ? (
                          <ServiceFinanceTreeCell
                            service={row}
                            channel={row.channelRow}
                            onEditService={onEditService}
                          />
                        ) : !isYearTable && row.isChannel ? null : (
                          <ChannelTreeNode
                            visible={visible}
                            expanded={expanded}
                            onToggle={onToggle}
                            content={content}
                            value={value}
                            isChannel={row.isChannel}
                            tools={[getTool()]}
                          />
                        )}
                      </Stack>
                    </Table.Cell>
                  )}
                </TemplatePlaceholder>
              );
            }}
          </TemplateConnector>
        )}
      </Template>
    </Plugin>
  );
};

export const getChildRows = (row: FilialModel, rootRows: FilialModel[]) => {
  let childRows = rootRows.filter(
    (r) => r.parentFilialId === (row ? row.filialId : null),
  );

  if (
    row &&
    (row.filialTypeId === FilialType.ПБО ||
      (row.filialTypeId === FilialType.ГлавноеУправление &&
        childRows.length === 0)) &&
    row.channels.length > 0
  ) {
    return row.channels.map((ch) => ({
      ...ch,
      isChannel: true,
      filialRow: row,
      filialId: `${row.filialId}-${ch.id}`,
    }));
  }

  if (row && (row as any).isChannel) {
    const channelRow = row as unknown as GetDataChannelModel;
    const { filialRow } = row as any;

    return channelRow.services.map((s) => ({
      ...s,
      isService: true,
      filialRow,
      channelRow,
      filialId: `${filialRow.filialId}-${channelRow.id}-${s.id}`,
    }));
  }

  if (row && row.filialTypeId === FilialType.КПП) {
    childRows = childRows.sort((a, b) => {
      const aFilialCode = a.filialCode || 0;
      const bFilialCode = b.filialCode || 0;

      if (aFilialCode < bFilialCode) {
        return -1;
      }

      if (aFilialCode > bFilialCode) {
        return 1;
      }

      return 0;
    });
  }

  return childRows.length ? childRows : null;
};

const isTreeTableCell = (tableRow: any, tableColumn: any, forColumnName: any) =>
  tableRow.type === TABLE_DATA_TYPE &&
  tableColumn.type === TABLE_DATA_TYPE &&
  tableColumn.column!.name === forColumnName;

const Indent = memo(({ level }: { level: number }) => (
  <div style={{ position: 'relative', top: -4 }}>
    {Array.from({ length: level }).map((_value, currentLevel) => (
      <span
        key={currentLevel}
        className="d-inline-block me-4"
        style={{ display: 'inline-block', position: 'static' }}
      >
        <div
          style={{
            position: 'absolute',
            left: (currentLevel + 1) * 24,
            top: -27,
            height: '70px',
            borderLeft: '1px solid gray',
          }}
        />
      </span>
    ))}
  </div>
));

export const ChannelTreeColumn: React.ComponentType<Props> =
  ChannelTreeColumnBase;
