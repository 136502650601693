import React from 'react';

import { BarDisplay } from 'gantt-task-react/components/task-item/bar/bar-display';
import { BarDateHandle } from 'gantt-task-react/components/task-item/bar/bar-date-handle';

import { TaskItemProps } from '../task-item';
import styles from './milestone.module.css';

export const Milestone: React.FC<TaskItemProps> = ({
  task,
  isDateChangeable,
  onEventStart,
  isSelected,
}) => {
  const getBarColor = () =>
    isSelected
      ? task.styles.backgroundSelectedColor
      : task.styles.backgroundColor;

  return (
    <g className={styles.barWrapper} tabIndex={0}>
      <BarDisplay
        x={task.x1}
        y={task.y}
        width={task.x2 - task.x1}
        height={task.height}
        progressX={task.progressX}
        progressWidth={task.progressWidth}
        barCornerRadius={task.barCornerRadius}
        styles={task.styles}
        isSelected={isSelected}
        onMouseDown={(e) => {
          if (isDateChangeable) {
            onEventStart('move', task, e);
          }
        }}
      />
      <g className="handleGroup">
        {isDateChangeable && (
          <g>
            {/* left */}
            <BarDateHandle
              x={task.x1 + 1}
              y={task.y + 1}
              width={task.handleWidth}
              height={task.height}
              barCornerRadius={task.barCornerRadius}
              onMouseDown={(e) => {
                onEventStart('start', task, e);
              }}
            />
            {/* right */}
            <BarDateHandle
              x={task.x2 - task.handleWidth - 1}
              y={task.y + 1}
              width={task.handleWidth}
              height={task.height}
              barCornerRadius={task.barCornerRadius}
              onMouseDown={(e) => {
                onEventStart('end', task, e);
              }}
            />
          </g>
        )}
      </g>
    </g>
  );
};
