import { Table } from '@devexpress/dx-react-grid';
import classNames from 'clsx';
import styled from 'styled-components';

type CellProps = Table.DataCellProps & {
  forwardedRef?: any;
  className?: string;
};

const StyledCell = styled.td`
  padding: 4px;
`;

const StyledInnerCell = styled.div`
  border: 1px solid gray;
  border-radius: 3px;
  text-align: center;
  min-height: 50px;
  background-color: #f5f5f5;
`;

export const SimpleCell: React.ComponentType<CellProps> = ({
  column,
  value,
  children,
  tableRow,
  tableColumn,
  row,
  forwardedRef,
  className,
  ...restProps
}) => (
  <StyledCell
    className={classNames(
      {
        'dx-g-bs5-table-cell': true,
        'text-nowrap': false,
        'text-right': tableColumn && tableColumn.align === 'right',
        'text-center': tableColumn && tableColumn.align === 'center',
      },
      className,
    )}
    ref={forwardedRef}
    {...restProps}
  >
    <StyledInnerCell>{children || value}</StyledInnerCell>
  </StyledCell>
);
