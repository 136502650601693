import { ToolsAll } from 'store/dto/ToolsAll';
import { api } from './api';

export interface ToolsAllRequest {
  Offset: number;
  Size: number;
}

export const toolsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    toolsAll: builder.query<ToolsAll, ToolsAllRequest>({
      query: (params) => ({
        url: '/Tool/all',
        params,
      }),
    }),
  }),
});

export const { useToolsAllQuery } = toolsApi;
