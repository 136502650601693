import { useEffect } from 'react';
import { useCreateGuestLinkMutation } from 'store/services/gantt';
import { useModal } from 'hooks/useModal';
import { Button, Stack } from 'react-bootstrap';

export type TrustKeyProps = {
  trustProjectId: number | null;
  setTrustProjectId: React.Dispatch<React.SetStateAction<number | null>>;
};

export const TrustModal = (props: TrustKeyProps): any => {
  const [createGuestLink] = useCreateGuestLinkMutation();
  const modalHook = useModal();
  const { trustProjectId, setTrustProjectId } = props;

  const handleClose = (modalId: number) => {
    modalHook.close(modalId);
    setTrustProjectId(null);
  };

  const handleCopyBtn = (trustLink: string, prevModalId: number) => {
    navigator.clipboard.writeText(trustLink);
    modalHook.close(prevModalId);

    const modalId = modalHook.open({
      title: '✔ Ссылка скопирована!',
      bodyRenderer: () => {
        return (
          <Stack gap={3} style={{ justifyContent: 'center' }}>
            <Stack
              direction="horizontal"
              gap={3}
              style={{ justifyContent: 'center' }}
            >
              <Button
                variant="outline-secondary"
                onClick={() => handleClose(modalId)}
              >
                Закрыть
              </Button>
            </Stack>
          </Stack>
        );
      },
      onClose: () => handleClose(modalId),
    });
  };

  const fetchAndOpenTrust = async (props: TrustKeyProps) => {
    const result = await createGuestLink({
      projectNumber: trustProjectId,
      linkType: 1,
    }).unwrap();

    if (result?.trustKey) {
      const { trustKey } = result;
      const trustLink = `${window.location.origin}/login?trustKey=${trustKey}&returnLink=/gantt/${trustProjectId}`;

      const modalId = modalHook.open({
        title: 'Поделиться проектом',
        bodyRenderer: () => {
          return (
            <Stack gap={3} style={{ justifyContent: 'center' }}>
              <span style={{ textAlign: 'center' }}>
                Дать доступ по ссылке на просмотр проекта
              </span>
              <div style={{ display: 'flex' }}>
                <input type="text" value={trustLink} style={{ flexGrow: 1 }} />
              </div>
              <Stack
                direction="horizontal"
                gap={3}
                style={{ justifyContent: 'center' }}
              >
                <Button
                  variant="outline-warning"
                  onClick={() => handleCopyBtn(trustLink, modalId)}
                >
                  Скопировать ссылку
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => handleClose(modalId)}
                >
                  Закрыть
                </Button>
              </Stack>
            </Stack>
          );
        },
        onClose: () => handleClose(modalId),
      });
    }
  };

  useEffect(() => {
    if (trustProjectId) {
      fetchAndOpenTrust(props);
    }
  }, [trustProjectId]);
};
