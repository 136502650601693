import { FC } from 'react';

import { LogoutButton, Typography } from 'components';
import { styled } from 'styled-components';
import { useAuth } from 'hooks/useAuth';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: ${({ theme }) => theme.spacing(4)};
  padding-block: ${({ theme }) => theme.spacing(0.5)};
  background-color: ${({ theme }) => theme.palette.gray};
`;

const AppBar: FC = () => {
  const { user } = useAuth();

  return (
    <Container>
      <Typography>
        {user?.surName} {user?.name} {user?.patronymic}
      </Typography>
      <LogoutButton
        onClick={() => {
          localStorage.clear();
          document.location.pathname = '/login';
        }}
      />
    </Container>
  );
};

export default AppBar;
