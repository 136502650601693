import { PopoverContainer } from 'components/containers/Popover';
import { CrossIcon, PencilIcon } from 'components/icons';
import { Typography } from 'components/typography';
import { Month } from 'lib/enums/Month';
import { GetDataChannelModel } from 'store/dto/GetDataChannelModel';
import { GetDataServiceFinanceModel } from 'store/dto/GetDataServiceFinanceModel';
import { GetDataServiceModel } from 'store/dto/GetDataServiceModel';
import styled from 'styled-components';
import { COLORS, themeProvider } from 'theme';

const ServicePopoverContainer = styled(PopoverContainer)`
  padding: 3px;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2)};
  max-width: 20rem;
  white-space: normal;
  min-width: 500px;
  text-align: initial;
`;

const Header = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing(3)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(1)};
  padding: 0 ${({ theme }) => theme.spacing(5)};
`;

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  color: ${COLORS.black};
`;

const FormItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;
interface FormItemProps {
  label: string;
  children: React.ReactNode;
}
const FormItem: React.FC<FormItemProps> = ({ children, label }) => (
  <FormItemContainer>
    <Typography variant="body2" color={themeProvider.palette.lgreen}>
      {label}:
    </Typography>
    {children}
  </FormItemContainer>
);

interface ServicePopoverProps {
  service: GetDataServiceModel;
  channel: GetDataChannelModel;
  onClickClose: () => void;
  onEditService: (
    channel: GetDataChannelModel,
    service: GetDataServiceModel,
  ) => void;
}

export const ServicePopover: React.FC<ServicePopoverProps> = ({
  service,
  channel,
  onClickClose,
  onEditService,
}) => (
  <ServicePopoverContainer>
    <Header>
      <Typography variant="body2">Информация</Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: `${themeProvider.spacing(1)}`,
          alignItems: 'center',
          width: 50,
        }}
      >
        <IconContainer onClick={() => onEditService(channel, service)}>
          <PencilIcon />
        </IconContainer>
        <IconContainer onClick={onClickClose}>
          <CrossIcon />
        </IconContainer>
      </div>
    </Header>
    <Content>
      <FormItem label="Канал, инструмент">
        <Typography variant="caption">
          {channel.channelName} - {service.toolName}
        </Typography>
      </FormItem>
      <FormItem label="Адрес">
        <Typography
          variant="body2"
          style={{
            wordBreak: 'break-all',
          }}
        >
          {service.address}
        </Typography>
      </FormItem>
      <FormItem label="Контрагент">
        <Typography
          variant="body2"
          style={{
            wordBreak: 'break-all',
          }}
        >
          {service.contractorName}
        </Typography>
      </FormItem>
      <FormItem label="Период">
        <Typography variant="body2">
          {formatFinancePeriod(service.serviceFinance)}
        </Typography>
      </FormItem>
      <FormItem label="Описание">
        <Typography
          variant="body2"
          style={{
            wordBreak: 'break-all',
          }}
        >
          {service.description}
        </Typography>
      </FormItem>
    </Content>
  </ServicePopoverContainer>
);

const formatFinancePeriod = (serviceFinances: GetDataServiceFinanceModel[]) => {
  if (serviceFinances.length === 1) {
    const sf = serviceFinances[0];
    return `${Month[sf.month]}.${sf.year}`;
  }
  if (serviceFinances.length > 1) {
    const sortedSF = [...serviceFinances].sort((a, b) => {
      if (a.year <= b.year && a.month < b.month) {
        return -1;
      }
      if (a.year >= b.year && a.month > b.month) {
        return 1;
      }
      return 0;
    });

    const sfFirst = sortedSF[0];
    const sfLast = sortedSF[sortedSF.length - 1];

    return `${Month[sfFirst.month]}.${sfFirst.year} - ${Month[sfLast.month]}.${
      sfLast.year
    }`;
  }

  return '';
};
