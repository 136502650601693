import { FlexContainer } from 'components/containers';
import React from 'react';
import { Button } from 'react-bootstrap';
import { themeProvider } from 'theme';
import { FormField, register } from 'components/inputs/FormField';
import { useGantt } from 'hooks/useGantt';
import { DateTimeInput } from 'components/inputs/DateTimeInput';
import {
  FormFieldCheck,
  checkRegister,
} from 'components/inputs/FormFieldCheck';
import Modal from 'components/modal/modal';
import { getLevelLabelRo } from 'lib/enums/GantInfoType';
import { ModalOpenLabel } from 'pages/GanttPage/TaskListTable';
import { useAuth } from 'hooks/useAuth';

export interface GanttEditFormProps {
  onClose: () => void;
  onSubmitted: () => void;
  isModalOpenAndLabel: ModalOpenLabel | null;
}

const inputPaddingLeft = 5;

const getStatusLabel = (status: boolean) => {
  if (status) {
    return (
      <span style={{ color: '#198754', paddingLeft: 3, fontSize: 14 }}>
        Выполнено
      </span>
    );
  }
  return (
    <span style={{ color: '#ffa202', paddingLeft: 3, fontSize: 14 }}>
      В работе
    </span>
  );
};

const GanttEditForm: React.FC<GanttEditFormProps> = ({
  onSubmitted,
  onClose,
  isModalOpenAndLabel,
}) => {
  const { form, editableTask, levels, saveTask } = useGantt();
  const { isGuest } = useAuth();
  const typeId = editableTask?.typeId || 3;
  const taskLevelLabel = getLevelLabelRo(typeId);

  if (isModalOpenAndLabel && !isGuest) {
    return (
      <Modal
        title={`${isModalOpenAndLabel} ${taskLevelLabel}`}
        onClose={onClose}
      >
        <FlexContainer
          flexDirection="column"
          gap={themeProvider.spacing(3)}
          width={440}
        >
          <div>
            <div>Наименование</div>
            <FormField
              autoFocus
              floatingError
              size="sm"
              style={{ paddingLeft: inputPaddingLeft }}
              placeholder=""
              {...register('name', form)}
            />
          </div>

          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>
              <div>Дата начала</div>
              <div
                style={{
                  display: 'inline-block',
                  border: '1px solid gray',
                  borderRadius: 'var(--bs-border-radius-sm)',
                  minHeight: 32,
                }}
              >
                <FormField
                  floatingError
                  InputComponent={DateTimeInput}
                  size="sm"
                  {...register('startDate', form)}
                />
              </div>
            </div>
            <div style={{ flexGrow: 0 }}>
              <div>Дата окончания</div>
              <div
                style={{
                  display: 'inline-block',
                  border: '1px solid gray',
                  borderRadius: 'var(--bs-border-radius-sm)',
                  minHeight: 32,
                }}
              >
                <FormField
                  floatingError
                  InputComponent={DateTimeInput}
                  size="sm"
                  {...register('endDate', form)}
                />
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', gap: 40 }}>
            <div style={{ flexGrow: 1 }}>
              <div>Ответственный</div>
              <FormField
                size="sm"
                floatingError
                style={{ paddingLeft: inputPaddingLeft }}
                placeholder=""
                {...register('agent', form)}
              />
            </div>
            <div style={{ minWidth: 110 }}>
              <div>Статус</div>
              <div>
                <FormFieldCheck
                  label={getStatusLabel(form.values.status)}
                  {...checkRegister('status', form)}
                />
              </div>
            </div>
          </div>

          <div>
            <div>Примечание</div>
            <FormField
              size="sm"
              floatingError
              style={{ paddingLeft: inputPaddingLeft, minHeight: 80 }}
              placeholder=""
              type="textarea"
              {...register('note1', form)}
            />
          </div>

          <FlexContainer
            justifyContent="end"
            columnGap={themeProvider.spacing(2)}
          >
            <Button
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                saveTask();
                onSubmitted();
              }}
              disabled={!form.isValid}
            >
              Сохранить
            </Button>
            <Button variant="outline-secondary" onClick={onClose}>
              Отменить
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Modal>
    );
  }
  return null;
};

export default GanttEditForm;
