import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

type MonthColumnsExpandedState = {
  [columnName: string]: boolean;
};

const currentMonth = new Date().getMonth() + 1;

const defaultState: MonthColumnsExpandedState = {
  month_1: !(currentMonth > 1),
  month_2: !(currentMonth > 2),
  month_3: !(currentMonth > 3),
  month_4: !(currentMonth > 4),
  month_5: !(currentMonth > 5),
  month_6: !(currentMonth > 6),
  month_7: !(currentMonth > 7),
  month_8: !(currentMonth > 8),
  month_9: !(currentMonth > 9),
  month_10: !(currentMonth > 10),
  month_11: !(currentMonth > 11),
  month_12: true,
};

const monthColumnsExpandedSlice = createSlice({
  name: 'monthColumnsExpanded',
  initialState: defaultState,
  reducers: {
    setExpandedColumn: (
      state,
      {
        payload: { columnName, expanded },
      }: PayloadAction<{ columnName: string; expanded: boolean }>,
    ) => ({ ...state, [columnName]: expanded }),
  },
});

export const { setExpandedColumn } = monthColumnsExpandedSlice.actions;

export default monthColumnsExpandedSlice.reducer;

export const selectMonthColumnsExpanded = (state: RootState) =>
  state.monthColumnsExpanded;
