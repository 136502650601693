import { AuthResponse } from 'store/dto/AuthResponse';
import { api } from './api';

export interface LoginRequest {
  login: string;
  password: string;
}

export type trustKeyType = any;

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/Auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    trust: builder.mutation<AuthResponse, trustKeyType>({
      query: (trustKey) => ({
        url: '/Auth/TrustKey',
        method: 'POST',
        body: trustKey,
        headers: { 'Content-Type': 'application/json' },
      }),
    }),
  }),
});

export const { useLoginMutation, useTrustMutation } = authApi;
