export interface IPalette {
  orange: string;
  yellow: string;
  green: string;
  gray: string;
  black: string;
  white: string;
  seaport: string;
  lgreen: string;
  dgray: string;
  green15: string;
  orange15: string;
  shadowOverlay: string;
}

export const COLORS: IPalette = {
  orange: '#FB471F',
  yellow: '#FFE814',
  green: '#264F36',
  gray: '#F5F5F5',
  black: '#313131',
  white: '#FFFFFF',
  seaport: '#00577A',
  lgreen: '#4CA36E',
  dgray: '#868686',
  green15: 'rgba(76, 163, 110, 0.15)',
  orange15: '#EE7203',
  shadowOverlay: '#0000000C',
};
