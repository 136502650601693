import { FlexContainer } from 'components/containers';
import { FormikAsyncSelect } from 'components/formik';
import { selectOptionValidationSchema } from 'components/formik/validationSchemas';
import { Typography } from 'components/typography';
import { useFormik } from 'formik';
import { yup } from 'lib';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { COLORS, themeProvider } from 'theme';
import { FilialModel } from 'store/dto/FilialModel';
import { useChannelsAllQuery } from 'store/services/channels';
import { useToolsAllQuery } from 'store/services/tools';
import { useCreateServiceMutation } from 'store/services/filials';
import { differenceInMonths, parse } from 'date-fns';
import { useModal } from 'hooks/useModal';
import { Month } from 'lib/enums/Month';
import { getErrorMessage } from 'lib/getErrorMessage';
import { NumberInput } from 'components/inputs/NumberInput';
import { FormField, register } from 'components/inputs/FormField';
import { ToolModel } from 'store/dto/ToolModel';
import { SelectOptionsType } from 'components/inputs/Selects/types';

export interface AddInstrumentFormProps {
  parentFilial: FilialModel;
  onClose: () => void;
  onSubmitted: () => void;
  onCancel?: () => void;
}

interface FormFields {
  channel: {
    label: string;
    value: string;
  } | null;
  tool: {
    label: string;
    value: string;
  } | null;
  contractorName: string;
  amount: string;
  address: string;
  periodFrom: string;
  periodTo: string;
  description: string;
}

type chanelTreeType = Record<number, ToolModel[]>;

const validationSchema = yup.object().shape({
  channel: selectOptionValidationSchema,
  tool: selectOptionValidationSchema,
  contractorName: yup.string(),
  amount: yup.number().typeError('Должно быть введено число').required(),
  address: yup.string(),
  periodFrom: yup.date().required(),
  periodTo: yup
    .date()
    .required()
    .min(yup.ref('periodFrom'), '"Период до" не может быть раньше "Период с"'),
  description: yup.string(),
});

const AddInstrumentForm: React.FC<AddInstrumentFormProps> = ({
  parentFilial,
  onSubmitted,
  onCancel,
  onClose,
}) => {
  const [createService] = useCreateServiceMutation();
  const { data: channelsAllData } = useChannelsAllQuery({
    Offset: 0,
    Size: 100,
  });
  const { data: toolsAllData } = useToolsAllQuery({ Offset: 0, Size: 100 });
  const modalHook = useModal();

  const [tools, setTools] = useState<ToolModel[]>([]);
  const [chanelTree, setChanelTree] = useState<chanelTreeType>({});

  const getToolOptions = (): SelectOptionsType[] => {
    if (tools && tools.length) {
      return tools.map(({ name, id }) => ({ label: name, value: String(id) }));
    }
    return [];
  };

  useEffect(() => {
    const chanelTree: chanelTreeType = {};
    if (toolsAllData?.data && channelsAllData?.data) {
      toolsAllData.data.forEach((tool: ToolModel) => {
        if (tool?.channelIds) {
          tool.channelIds.forEach((channelId: number) => {
            chanelTree[channelId] = chanelTree[channelId] ?? [];
            chanelTree[channelId].push(tool);
          });
        }
      });
    }
    setChanelTree(chanelTree);
  }, [toolsAllData, channelsAllData]);

  const formik = useFormik<FormFields>({
    initialValues: {
      channel: {
        value: '',
        label: 'Выберите канал...',
      },
      tool: {
        value: '',
        label: 'Выберите инструмент...',
      },
      contractorName: '',
      amount: '',
      address: '',
      periodFrom: '',
      periodTo: '',
      description: '',
    },
    validationSchema,
    onSubmit: () => {},
    isInitialValid: false,
  });

  useEffect(() => {
    formik.setFieldValue('tool', {
      value: '',
      label: 'Выберите инструмент...',
    });
  }, [formik.values.channel?.value]);

  const existsToolsIds: number[] = [];

  parentFilial.channels.forEach((ch) => {
    if (ch.id === Number.parseInt(formik.values.channel?.value as string, 10)) {
      ch.services.forEach((s) => {
        existsToolsIds.push(s.toolid);
      });
    }
  });

  const titleText = [
    [
      [parentFilial.filialCode, parentFilial.filialName]
        .filter((x) => !!x)
        .join('-'),
      parentFilial.filialCity,
    ]
      .filter((x) => !!x)
      .join(', '),
    parentFilial.filialAddress,
  ]
    .filter((x) => !!x)
    .join(', ');

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    onClose();
  }, [formik.values, onCancel, onClose]);

  const handleSubmit = useCallback(async () => {
    const dateStart = parse(formik.values.periodFrom, 'yyyy-MM-dd', new Date());
    const dateEnd = parse(formik.values.periodTo, 'yyyy-MM-dd', new Date());

    let diff = differenceInMonths(dateEnd, dateStart);

    const periodMonths = [
      { month: dateStart.getMonth() + 1, year: dateStart.getFullYear() },
    ];

    while (diff > 0) {
      const lastDate = periodMonths[periodMonths.length - 1];

      periodMonths.push({
        month: lastDate.month === 12 ? 1 : lastDate.month + 1,
        year: lastDate.month === 12 ? lastDate.year + 1 : lastDate.year,
      });

      diff--;
    }

    try {
      const allResult = (
        await Promise.allSettled(
          periodMonths.map((p) =>
            createService({
              filialId: parentFilial.filialId,
              channelId: Number.parseInt(formik.values.channel!.value, 10),
              month: p.month,
              year: p.year,
              serviceFinance: {
                amount: Number.parseFloat(formik.values.amount),
                toolId: Number.parseInt(formik.values.tool!.value, 10),
                address: formik.values.address,
                contractorName: formik.values.contractorName,
                description: formik.values.description,
              },
            }).unwrap(),
          ),
        )
      ).map((x, idx) => ({ ...x, idx }));

      onSubmitted();
      onClose();

      const fulfilledResults = allResult.filter(
        (x) => x.status === 'fulfilled',
      );
      const rejectedResults = allResult.filter((x) => x.status === 'rejected');

      modalHook.open({
        okButton: true,
        variant: 'success',
        title: 'Добавление инструмента',
        bodyRenderer: () => {
          if (rejectedResults.length === 0) {
            return (
              <span>Инструмент на указанный период успешно добавлен!</span>
            );
          }
          return (
            <div>
              {fulfilledResults.length > 0 && (
                <>
                  <span>Инструмент успешно добавлен: </span>
                  <ul>
                    {fulfilledResults.map((x) => (
                      <li>
                        {Month[periodMonths[x.idx].month]}{' '}
                        {periodMonths[x.idx].year}
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {rejectedResults.length > 0 && (
                <>
                  <span style={{ color: COLORS.orange15 }}>Ошибка: </span>
                  <ul style={{ color: COLORS.orange15 }}>
                    {rejectedResults.map((x) => (
                      <li>
                        {Month[periodMonths[x.idx].month]}{' '}
                        {periodMonths[x.idx].year}: {getErrorMessage(x)}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          );
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [formik.values, onClose, onSubmitted]);

  return (
    <FlexContainer
      flexDirection="column"
      rowGap={themeProvider.spacing(3)}
      width={500}
    >
      <Typography
        variant="body1"
        style={{ width: 'fit-content', margin: '0 auto' }}
      >
        {titleText}
      </Typography>
      <FlexContainer columnGap={themeProvider.spacing(3)}>
        <FormikAsyncSelect
          formik={formik}
          defaultOptions={channelsAllData?.data.map((r) => ({
            value: r.id.toString(),
            label: r.name,
          }))}
          label="Канал"
          fieldName="channel"
          cb={(selectedOption) => {
            setTools(chanelTree[Number(selectedOption.value)]);
          }}
        />
        <FormikAsyncSelect
          formik={formik}
          defaultOptions={getToolOptions()}
          label="Инструмент"
          fieldName="tool"
          disabled={!formik.values.channel?.value}
        />
      </FlexContainer>
      <FlexContainer columnGap={themeProvider.spacing(3)} alignItems="center">
        <FormField label="Контрагент" {...register('contractorName', formik)} />
        <FormField
          InputComponent={NumberInput}
          label="Сумма / месяц"
          {...register('amount', formik)}
        />
      </FlexContainer>
      <FlexContainer columnGap={themeProvider.spacing(3)}>
        <FormField
          type="textarea"
          label="Месторасположение"
          {...register('address', formik)}
          fullHeight
        />
        <FlexContainer
          flexDirection="column"
          rowGap={themeProvider.spacing(1)}
          width="100%"
        >
          <FormField
            type="date"
            label="Период с"
            {...register('periodFrom', formik)}
          />
          <FormField
            type="date"
            label="Период по"
            {...register('periodTo', formik)}
          />
        </FlexContainer>
      </FlexContainer>
      <FormField
        type="textarea"
        label="Описание"
        {...register('description', formik)}
        style={{ minHeight: themeProvider.spacing(14) }}
      />
      <FlexContainer justifyContent="end" columnGap={themeProvider.spacing(2)}>
        <Button onClick={handleCancel} variant="outline-light">
          Отмена
        </Button>
        <Button
          onClick={handleSubmit}
          variant="light"
          disabled={!formik.isValid}
        >
          Сохранить
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default AddInstrumentForm;
