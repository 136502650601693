import { FC, ReactNode, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'store/store';
import { clearCredentials, selectAuthToken } from 'store/features/authSlice';
import { useDispatch } from 'react-redux';
import { LogoIcon, SoloGidIcon } from '../../icons';

const isDevMode = process.env.NODE_ENV === 'development';

interface Props {
  icon?: ReactNode;
}

const CountDown = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  color: #b9b9b9;
  font-size: 12px;
`;

const Container = styled.header`
  position: relative;
  background: ${({ theme }) => theme.palette.green};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: ${({ theme }) => theme.spacing(4)};
`;

const AppHeader: FC<Props> = ({ icon }) => {
  const [countdownSec, setCountdownSec] = useState(600);
  const [intervalId, setIntervalId] = useState<any>();
  const token = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const isCountDown = !isDevMode && !process.env.REACT_APP_GANTT && token;

  useEffect(() => {
    if (!isCountDown) {
      return;
    }

    const idleDurationMins = 10;
    let idleTimeout: ReturnType<typeof setTimeout>;
    let startTime = new Date().getTime();

    if (intervalId) {
      clearInterval(intervalId);
    }

    if (token) {
      setIntervalId(
        setInterval(() => {
          const currentTime = new Date().getTime();
          const sec = Math.round((currentTime - startTime) / 1000);

          setCountdownSec(countdownSec - sec);
        }, 1000),
      );
    }
    const resetIdleTimeout = () => {
      if (idleTimeout) clearTimeout(idleTimeout);
      startTime = new Date().getTime();
      idleTimeout = setTimeout(
        () => {
          dispatch(clearCredentials());
          document.location.pathname = '/login';
          clearInterval(intervalId);
        },
        idleDurationMins * 60 * 1000,
      );
    };

    if (token) {
      resetIdleTimeout();
      window.onclick = resetIdleTimeout;
      window.onfocus = resetIdleTimeout;
      window.onchange = resetIdleTimeout;
      window.onmousedown = resetIdleTimeout;
      window.onmouseup = resetIdleTimeout;
      window.onkeydown = resetIdleTimeout;
      window.onkeyup = resetIdleTimeout;
      window.onreset = resetIdleTimeout;
      window.onselect = resetIdleTimeout;
      window.onscroll = resetIdleTimeout;
    }

    return () => {
      window.onclick = null;
      window.onfocus = null;
      window.onchange = null;
      window.onmousedown = null;
      window.onmouseup = null;
      window.onkeydown = null;
      window.onkeyup = null;
      window.onreset = null;
      window.onselect = null;
      window.onscroll = null;
      clearTimeout(idleTimeout);
      clearInterval(intervalId);
    };
  }, [token, setIntervalId]);

  const getTimer = () => {
    if (isDevMode) {
      return <CountDown>Режим разработчика</CountDown>;
    }
    if (isCountDown) {
      return (
        token && (
          <CountDown>
            Автоматическое завершение сессии: {formatSeconds(countdownSec)}
          </CountDown>
        )
      );
    }
    return null;
  };

  return (
    <Container>
      {getTimer()}

      {process.env.REACT_APP_GANTT ? (
        <div
          style={{
            fontSize: '30px',
            color: 'rgba(255, 255, 255, 1)',
            textShadow: '0.5px 0.5px 3px rgba(0, 0, 0, 0.3)',
          }}
        >
          TimeLine
        </div>
      ) : (
        <a href="/" style={{ cursor: 'pointer', textDecoration: 'none' }}>
          <LogoIcon />
        </a>
      )}

      {process.env.REACT_APP_GANTT ? null : <SoloGidIcon />}
    </Container>
  );
};

const formatSeconds = (secs: number) => {
  const pad = (n: number) => (n < 10 ? `0${n}` : n);

  const h = Math.floor(secs / 3600);
  const m = Math.floor(secs / 60) - h * 60;
  const s = Math.floor(secs - h * 3600 - m * 60);

  return `${pad(m)}:${pad(s)}`;
};

export default AppHeader;
