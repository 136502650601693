import { useState } from 'react';

type TriggerTooltipProps = {
  children: React.ReactElement;
  text: string;
};

export const TriggerTooltip = (props: TriggerTooltipProps) => {
  const { children, text } = props;

  const [show, setShow] = useState<boolean>(false);

  return (
    <div
      style={{ position: 'relative' }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}

      {show && (
        <div
          style={{
            position: 'absolute',
            right: 30,
            top: -5,
            padding: '5px 10px',
            backgroundColor: '#ffffff',
            boxShadow: '0 0 10px 0 rgba(0,0,0,0.4)',
            width: 300,
            zIndex: 100,
            whiteSpace: 'normal',
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};
