import { css } from 'styled-components';

export const modalStyle = css`
  .modal {
    &--default {
      .modal-content {
        border-color: transparent;
      }
    }

    &--warning {
      .modal-content {
        border-color: ${({ theme }) => theme.palette.orange};
      }
    }

    &-sm {
      --bs-modal-width: 400px;
    }

    &-lg {
      --bs-modal-width: 560px;
    }

    &-xl {
      --bs-modal-width: 820px;
    }

    &-content {
      box-shadow: 8px 4px 8px rgba(0, 0, 0, 0.25);
      border-color: transparent;

      &:has(.modal-header) {
        .modal-body {
          padding-top: 0;
        }
      }
    }

    &-header {
      padding-inline: ${({ theme }) => theme.spacing(3)};
      padding-block: ${({ theme }) => theme.spacing(2)};
      border-bottom: 0;
      display: block;
    }

    &-title {
      ${({ theme }) => ({ ...theme.typography.subtitle1 })}
      color: ${({ theme }) => theme.palette.orange};
      font-weight: 400;
    }

    &-body {
      padding-inline: ${({ theme }) => theme.spacing(3)};
      padding-block: ${({ theme }) => theme.spacing(2)};
    }

    &-footer {
      padding-inline: ${({ theme }) => theme.spacing(3)};
      padding-bottom: ${({ theme }) => theme.spacing(2)};
      padding-top: 0;
      border-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: ${({ theme }) => theme.spacing(2)};

      button {
        padding-inline: ${({ theme }) => theme.spacing(3)};
      }
    }
  }
`;
