import { yup } from 'lib';

export const selectOptionValidationSchema = yup
  .object({
    label: yup.string(),
    value: yup.string(),
  })
  .test(
    'is-filled',
    'Обязательное поле',
    (value) => !!(value && value.label && value.value),
  );
