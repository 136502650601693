import { css } from 'styled-components';

export const selectStyle = css`
  .form-select {
    &:focus {
      box-shadow: none;
      border-color: ${({ theme }) => theme.palette.black};
    }

    &-sm {
      height: 32px;
    }
  }
`;
