import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getChildRows } from 'components/table/ChannelTreeColumn';
import { FilialType } from 'lib/enums/FilialType';
import { ChannelFinanceModel } from 'store/dto/ChannelFinanceModel';
import { FilialModel } from 'store/dto/FilialModel';
import { GetDataServiceModel } from 'store/dto/GetDataServiceModel';
import { RootState } from 'store/store';

type RowsExpandedState = Array<string | number>;

export type expandRowsTypeType = FilialType | 'all';

const defaultState: RowsExpandedState = [];

const expandedRowsSlice = createSlice({
  name: 'rowsExpanded',
  initialState: defaultState,
  reducers: {
    setExpandedRows: (
      state,
      { payload }: PayloadAction<Array<string | number>>,
    ) => payload,
    collapseAll: () => [],
    expandAll: (
      state,
      {
        payload: { data, expandRowsType },
      }: PayloadAction<{
        data: FilialModel[];
        expandRowsType: expandRowsTypeType;
      }>,
    ) => {
      const pbos = data.filter((r) => r.filialTypeId === FilialType.ПБО);
      const channels: Array<{
        isChannel: boolean;
        filialRow: FilialModel;
        filialId: string;
        id: number;
        channelName?: string | undefined;
        channelFinance: ChannelFinanceModel[];
        services: GetDataServiceModel[];
      }> = [];

      pbos?.forEach((pboRow) => {
        const channelsRows = getChildRows(pboRow, data);

        if (channelsRows && channelsRows.length) {
          channels.push(...(channelsRows as any));
        }
      });

      switch (expandRowsType) {
        case FilialType.МПП:
          return [
            ...data
              .filter((r) => r.filialTypeId === FilialType.ГлавноеУправление)
              ?.map((r) => r.filialId),
          ];
        case FilialType.КПП:
          return [
            ...data
              .filter((r) => r.filialTypeId === FilialType.ГлавноеУправление)
              ?.map((r) => r.filialId),
            ...data
              .filter((r) => r.filialTypeId === FilialType.МПП)
              ?.map((r) => r.filialId),
          ];
        case FilialType.ПБО:
          return [
            ...data
              .filter((r) => r.filialTypeId === FilialType.ГлавноеУправление)
              ?.map((r) => r.filialId),
            ...data
              .filter((r) => r.filialTypeId === FilialType.МПП)
              ?.map((r) => r.filialId),
            ...data
              .filter((r) => r.filialTypeId === FilialType.КПП)
              ?.map((r) => r.filialId),
          ];
        default:
          return [
            ...data
              .filter((r) => r.filialTypeId === FilialType.ГлавноеУправление)
              ?.map((r) => r.filialId),
            ...data
              .filter((r) => r.filialTypeId === FilialType.МПП)
              ?.map((r) => r.filialId),
            ...data
              .filter((r) => r.filialTypeId === FilialType.КПП)
              ?.map((r) => r.filialId),
            ...data
              .filter((r) => r.filialTypeId === FilialType.ПБО)
              ?.map((r) => r.filialId),
            ...channels?.map((r) => r.filialId),
          ];
      }
    },
  },
});

export const { setExpandedRows, collapseAll, expandAll } =
  expandedRowsSlice.actions;

export default expandedRowsSlice.reducer;

export const selectRowsExpanded = (state: RootState) => state.rowsExpanded;
