import React, { FC, SVGProps } from 'react';

const Signout: FC<SVGProps<SVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66666 9.99998L0.885788 9.37528L0.386032 9.99998L0.885788 10.6247L1.66666 9.99998ZM13.3333 11C13.8856 11 14.3333 10.5523 14.3333 9.99998C14.3333 9.44769 13.8856 8.99998 13.3333 8.99998V11ZM4.21912 5.20862L0.885788 9.37528L2.44753 10.6247L5.78086 6.45801L4.21912 5.20862ZM0.885788 10.6247L4.21912 14.7913L5.78086 13.542L2.44753 9.37528L0.885788 10.6247ZM1.66666 11H13.3333V8.99998H1.66666V11Z"
      stroke="currentColor"
    />
    <path
      d="M8.33334 6.77663V6.66669C8.33334 5.73738 8.33334 5.27272 8.4102 4.88633C8.72583 3.29956 9.96622 2.05917 11.553 1.74355C11.9394 1.66669 12.404 1.66669 13.3333 1.66669V1.66669C14.2627 1.66669 14.7273 1.66669 15.1137 1.74355C16.7005 2.05917 17.9409 3.29956 18.2565 4.88633C18.3333 5.27272 18.3333 5.73738 18.3333 6.66669V13.3334C18.3333 14.2627 18.3333 14.7273 18.2565 15.1137C17.9409 16.7005 16.7005 17.9409 15.1137 18.2565C14.7273 18.3334 14.2534 18.3334 13.3057 18.3334V18.3334C12.4093 18.3334 11.9612 18.3334 11.5954 18.2647C9.97435 17.9604 8.7063 16.6923 8.40201 15.0713C8.33334 14.7055 8.33334 14.2665 8.33334 13.3883V13.3883"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default Signout;
