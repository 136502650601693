import { FC, useEffect, useState } from 'react';

import { Button, Card, Form } from 'react-bootstrap';

import styled from 'styled-components';
import { themeProvider } from 'theme';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { FlexContainer, Typography } from 'components';
import { yup } from 'lib';
import { useFormik } from 'formik';
import { useLoginMutation, useTrustMutation } from 'store/services/auth';
import { useAuth } from 'hooks/useAuth';
import { useModal } from 'hooks/useModal';
import { FormField, register } from 'components/inputs/FormField';

const Container = styled.div`
  padding-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface FormFields {
  login: string;
  password: string;
}

const validationSchema = yup.object().shape({
  login: yup.string().required(),
  password: yup.string().required(),
});

const LoginPage: FC = () => {
  const modalHook = useModal();
  const [login, { isLoading: isLoadingLogin }] = useLoginMutation();
  const [trust, { isLoading: isLoadingTrust }] = useTrustMutation();
  const [prepareForTrust, setPrepareForTrust] = useState(false);
  const [startFetchTrust, setStartFetchTrust] = useState(false);

  const { setAuthInfo } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const trustKey = searchParams.get('trustKey');
  const returnLink = searchParams.get('returnLink');

  const formik = useFormik<FormFields>({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      loginHandler(values);
    },
  });

  const fetchTrust = async (trustKey: string) => {
    const trustBody = JSON.stringify(trustKey);

    try {
      const { token, name, surName, patronymic } =
        await trust(trustBody).unwrap();

      setAuthInfo(token, { name, surName, patronymic, isGuest: true });

      if (returnLink) {
        navigate(returnLink);
      }
    } catch (err: any) {
      let message = err.error || err.message;

      if (err.status === 400) {
        message = err.data.Message;
      }

      modalHook.open({
        okButton: true,
        variant: 'error',
        title: 'Ошибка авторизации по ссылке',
        bodyRenderer: () => (
          <div style={{ textAlign: 'center' }}>
            <b>{message}</b>
            <div style={{ maxWidth: 400 }}>
              Возможно данные для входа не корректные или обратитесь в
              техническую поддержку.
            </div>
          </div>
        ),
      });
    }
  };

  useEffect(() => {
    if (trustKey && !isLoadingTrust && !prepareForTrust) {
      setPrepareForTrust(true);
    }
  }, [trust]);

  useEffect(() => {
    if (trustKey && prepareForTrust && !startFetchTrust) {
      setStartFetchTrust(true);
      fetchTrust(trustKey);
    }
  }, [prepareForTrust]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const loginHandler = async (values: FormFields) => {
    try {
      const { token, name, surName, patronymic } = await login(values).unwrap();
      setAuthInfo(token, { name, surName, patronymic });
    } catch (err: any) {
      let message = err.error || err.message;

      if (err.status === 400) {
        message = err.data.Message;
      }

      modalHook.open({
        okButton: true,
        variant: 'error',
        title: 'Ошибка авторизации',
        bodyRenderer: () => (
          <div style={{ textAlign: 'center' }}>
            <b>{message}</b>
            <div style={{ maxWidth: 400 }}>
              Возможно данные для входа не корректные или обратитесь в
              техническую поддержку.
            </div>
          </div>
        ),
      });
    }
  };

  if (trustKey) {
    return (
      <Container>
        <Card style={{ width: 448 }}>
          <Card.Header>
            <Typography style={{ color: themeProvider.palette.white }}>
              Гостевой вход по ссылке
            </Typography>
          </Card.Header>

          <Card.Body>{isLoadingLogin ? 'Авторизация…' : 'Вход'}</Card.Body>
        </Card>
      </Container>
    );
  }

  return (
    <Container>
      <Card style={{ width: 448 }}>
        <Card.Header>
          <Typography style={{ color: themeProvider.palette.white }}>
            Вход в систему
          </Typography>
        </Card.Header>

        <Card.Body>
          <Form>
            <FlexContainer
              flexDirection="column"
              rowGap={themeProvider.spacing(3)}
            >
              <FormField
                autoFocus
                label="Логин:"
                placeholder="example@mailbox.com"
                {...register('login', formik)}
              />
              <FormField
                type="password"
                label="Пароль:"
                {...register('password', formik)}
              />
              <FlexContainer justifyContent="center">
                <Button
                  onClick={() => formik.handleSubmit()}
                  disabled={isLoadingLogin}
                >
                  {isLoadingLogin ? 'Авторизация…' : 'Вход'}
                </Button>
              </FlexContainer>
            </FlexContainer>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default LoginPage;
