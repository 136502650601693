import { FC } from 'react';
import { styled } from 'styled-components';

interface Props {
  vertical?: boolean;
  style?: Record<string, any>;
}

const VerticalDivider = styled.div`
  height: auto;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.dgray};
  flex-basis: 1px;
  flex-shrink: 0;
  margin-inline: ${({ theme }) => theme.spacing(2)};
`;

const Divider: FC<Props> = ({ vertical, style }) => {
  if (vertical) return <VerticalDivider />;

  return <hr style={style} />;
};

export default Divider;
