import React from 'react';
import styled from 'styled-components';
import { Typography } from 'components/typography';
import { Button, Card, Stack } from 'react-bootstrap';
import { scrollbar, themeProvider } from 'theme';

export interface ModalContainerProps {
  bodyRenderer: () => JSX.Element;
  title?: string;
  variant?: 'default' | 'success' | 'error';
  okButton?: boolean;
  onClickOk?: () => void;
  onClose?: () => void;
}

const Overlay = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.shadowOverlay};
  top: 0;
  display: flex;
  align-items: center;
`;

const ModalContent = styled(Card)`
  margin: 0 auto;
  outline: 2px solid ${({ theme }) => theme.palette.lgreen};

  & .card-header {
    background-color: ${({ theme }) => theme.palette.lgreen};
  }
`;

const ModalBody = styled(Card.Body)`
  ${scrollbar}
  height: 'fit-content';
  max-height: 90vh;
  overflow: auto;
`;

const ModalContainer: React.FC<ModalContainerProps> = ({
  bodyRenderer: Body,
  title,
  variant = 'default',
  okButton = false,
  onClickOk,
  onClose,
}) => {
  const headerColor = {
    default: '#4CA36E',
    success: '#4CA36E',
    error: '#E94A2A',
  }[variant];

  return (
    <Overlay onClick={onClose}>
      <ModalContent
        style={{ width: 'fit-content', minWidth: 448 }}
        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
      >
        <Card.Header style={{ backgroundColor: headerColor }}>
          <Typography
            style={{
              color: themeProvider.palette.white,
              width: 'fit-content',
              margin: '0 auto',
            }}
          >
            {title}
          </Typography>
        </Card.Header>

        <ModalBody>
          <Stack direction="vertical" gap={3}>
            <Body />
            {okButton && (
              <div style={{ textAlign: 'center' }}>
                <Button variant="success" onClick={onClickOk}>
                  Ok
                </Button>
              </div>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Overlay>
  );
};
export default ModalContainer;
