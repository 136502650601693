import { FC, useCallback } from 'react';

import { TreeDataState, CustomTreeData } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableFixedColumns,
  TableHeaderRow,
  VirtualTable,
} from 'dx-react-grid-bootstrap5';

import {
  ChannelTreeColumn,
  getChildRows,
} from 'components/table/ChannelTreeColumn';
import { useModal } from 'hooks/useModal';
import { EditableCell } from 'components/table/EditableCell';
import { Button, Spinner, Stack } from 'react-bootstrap';
import {
  useCreateServiceMutation,
  useDeleteServiceMutation,
  useDeleteServiceRowMutation,
  useUpdateServiceAmountMutation,
} from 'store/services/filials';
import { GetDataChannelModel } from 'store/dto/GetDataChannelModel';
import { FilialFinanceModel } from 'store/dto/FilialFinanceModel';
import { ChannelFinanceModel } from 'store/dto/ChannelFinanceModel';
import { GetDataServiceFinanceModel } from 'store/dto/GetDataServiceFinanceModel';
import { useGetDataFilter } from 'hooks/useGetDataFilter';
import { roundPercent } from 'lib/utils/number/roundPercent';
import { months } from 'lib/enums/Month';
import { getErrorMessage } from 'lib/getErrorMessage';
import { numToRub, yup } from 'lib';
import { useSwitchableGetData } from 'hooks/useSwitchableGetData';
import styled from 'styled-components';
import { getMonthText } from 'lib/getMonthText';
import { GridRootLayout } from 'layouts/GridRootLayouts';
import {
  selectMonthColumnsExpanded,
  setExpandedColumn,
} from 'store/features/monthColumnsExpandedSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleCell } from 'components/table/SimpleCell';
import { COLORS } from 'theme';
import {
  selectRowsExpanded,
  setExpandedRows,
} from 'store/features/expandedRowsSlice';
import { NumberInput } from 'components/inputs/NumberInput';
import { cleanObject } from 'lib/cleanObject';
import { formatFilialNameFromRow } from './formatFilialNameFromRow';

const monthPrefix = 'month_';

const defaultColumnWidth = 150;
const collapsedColumnWidth = 40;

const HeaderCell = styled.div`
  font-weight: normal;
  cursor: pointer;
  text-align: center;
  background-color: ${COLORS.gray};
`;

const formatChannelFinanceCellContent = (r: GetDataChannelModel) =>
  r.channelName;

const displayCellRenderer = ({ row, column }: any) => {
  const month = Number.parseInt(column.name.replace(monthPrefix, ''), 10);

  if (Number.isNaN(month)) return;

  if (row.filialTypeId) {
    const ff = row.filialFinance.find(
      (ff: FilialFinanceModel) => ff.month === month,
    ) as FilialFinanceModel;
    if (ff) {
      return (
        <>
          <div>{numToRub(ff.marketingAmountFact || 0)} р</div>
          <div>{roundPercent(ff.marketingPercentFact || 0)} %</div>
        </>
      );
    }
  }

  if (row.isChannel) {
    const cf = row.channelFinance.find(
      (cf: ChannelFinanceModel) => cf.month === month,
    ) as ChannelFinanceModel;
    if (cf) {
      return (
        <>
          <div>{numToRub(cf.amount!)} р</div>
          <div>{roundPercent(cf.percent)} %</div>
        </>
      );
    }
  }

  if (row.isService) {
    const sf = row.serviceFinance.find(
      (sf: GetDataServiceFinanceModel) => sf.month === month,
    ) as GetDataServiceFinanceModel;
    if (sf) {
      return (
        <>
          <div>{numToRub(sf.amount)} р</div>
          <div>{roundPercent(sf.percent || 0)} %</div>
        </>
      );
    }
  }

  return <div style={{ color: 'gray', paddingTop: 10 }}>Нет данных</div>;
};

export const YearTable: FC = () => {
  const modalHook = useModal();
  const expandedColumns = useSelector(selectMonthColumnsExpanded);
  const rowsExpanded = useSelector(selectRowsExpanded);
  const dispatch = useDispatch();
  const { year, city, pboType } = useGetDataFilter();
  const [updateServiceAmount] = useUpdateServiceAmountMutation();
  const [deleteService] = useDeleteServiceMutation();
  const [deleteServiceRow] = useDeleteServiceRowMutation();
  const [createService] = useCreateServiceMutation();

  const handleClickColumnName = useCallback(
    (columnName: string) => {
      const expanded = !expandedColumns[columnName];

      dispatch(setExpandedColumn({ columnName, expanded }));
    },
    [expandedColumns, dispatch],
  );

  const columns = [
    {
      name: 'filialName',
      title: (<HeaderCell>Каналы</HeaderCell>) as unknown as string,
      getCellValue: (r: any) =>
        (r.isChannel
          ? formatChannelFinanceCellContent
          : formatFilialNameFromRow)(r),
    },
    ...months.map((m, i) => {
      let titleText = m;
      const columnName = monthPrefix + (i + 1);

      if (expandedColumns[columnName] === false) {
        titleText = String(i + 1).padStart(2, '0');
      }

      return {
        name: columnName,
        title: (
          <HeaderCell onClick={() => handleClickColumnName(columnName)}>
            {titleText}
          </HeaderCell>
        ) as unknown as string,
      };
    }),
  ];

  const tableColumnExtensions: Table.ColumnExtension[] = [
    { columnName: 'filialName', width: 500, align: 'center' },
    ...months.map((_, i) => {
      const columnName = monthPrefix + (i + 1);
      let width = defaultColumnWidth;

      if (expandedColumns[columnName] === false) {
        width = collapsedColumnWidth;
      }

      return { columnName, align: 'center', width } as Table.ColumnExtension;
    }),
  ];

  const {
    data = [],
    isFetching,
    isLoading,
    refetch,
  } = useSwitchableGetData(cleanObject({ year, city, pboTypeId: pboType }));

  const editableCellRenderer = (cellProps: any) => {
    let cellData: any = null;
    let month: number;

    if (expandedColumns[cellProps.column.name] === false) {
      return (
        <SimpleCell {...cellProps}>
          <div style={{ color: 'gray', paddingTop: 10 }}>&nbsp;</div>
        </SimpleCell>
      );
    }

    if (cellProps.row.isService) {
      month = Number.parseInt(cellProps.column.name.replace('month_', ''), 10);

      if (!Number.isNaN(month)) {
        cellData = cellProps.row.serviceFinance.find(
          (sf: GetDataServiceFinanceModel) => sf.month === month,
        ) as GetDataServiceFinanceModel;
      }
    }

    const handleDelete = async (modalId: number) => {
      try {
        await deleteService(cellData.id).unwrap();
        refetch();
        modalHook.close(modalId);

        modalHook.open({
          okButton: true,
          variant: 'success',
          title: 'Удаление данных',
          bodyRenderer: () => (
            <span>
              Данные за <b>{getMonthText(cellProps.column)}</b> по инструменту{' '}
              <b>{cellProps.row.toolName}</b> успешно удалены
            </span>
          ),
        });
      } catch (e) {
        modalHook.open({
          okButton: true,
          variant: 'error',
          title: 'Удаление данных',
          bodyRenderer: () => (
            <span>
              Ошибка при удалении данных за{' '}
              <b>{getMonthText(cellProps.column)}</b> по инструменту{' '}
              <b>{cellProps.row.toolName}</b>
            </span>
          ),
        });
      }
    };

    const handleDeleteRow = async (modalId: number) => {
      try {
        const cellArr = cellProps.row.serviceFinance
          ? cellProps.row.serviceFinance.map((tool: any) => tool.id)
          : [];

        await deleteServiceRow(cellArr).unwrap();
        refetch();
        modalHook.close(modalId);

        modalHook.open({
          okButton: true,
          variant: 'success',
          title: 'Удаление данных',
          bodyRenderer: () => (
            <span>
              Данные за год по инструменту <b>{cellProps.row.toolName}</b>{' '}
              успешно удалены
            </span>
          ),
        });
      } catch (e) {
        modalHook.open({
          okButton: true,
          variant: 'error',
          title: 'Удаление данных',
          bodyRenderer: () => (
            <span>
              Ошибка при удалении данных за год по инструменту{' '}
              <b>{cellProps.row.toolName}</b>
            </span>
          ),
        });
      }
    };

    return (
      <EditableCell
        {...cellProps}
        canDelete={Boolean(cellProps.row.isService && cellData)}
        canEditable={Boolean(cellProps.row.isService)}
        displayRenderer={displayCellRenderer}
        initialValues={{ amount: cellData?.amount || 0 }}
        validationSchema={yup.object().shape({
          amount: yup
            .number()
            .required()
            .moreThan(0)
            .label('Финансовый показатель'),
        })}
        onCommitedCanges={async ({ amount }) => {
          const amountFloat = Number.parseFloat(amount);

          try {
            if (cellData) {
              await updateServiceAmount({
                id: cellData.id,
                amount: amountFloat,
              }).unwrap();
            } else {
              await createService({
                filialId: cellProps.row.filialRow.filialId,
                channelId: cellProps.row.channelRow.id,
                month,
                year,
                serviceFinance: {
                  amount: amountFloat,
                  toolId: cellProps.row.toolid,
                  address: cellProps.row.address,
                  contractorName: cellProps.row.contractorName,
                  description: cellProps.row.description,
                },
              }).unwrap();
            }
            await refetch().unwrap();
          } catch (e) {
            modalHook.open({
              okButton: true,
              title: 'Изменение финансовых данных',
              variant: 'error',
              bodyRenderer: () => <span>{getErrorMessage(e)}</span>,
            });
          }
        }}
        onDelete={async () =>
          new Promise((resolve, reject) => {
            const modalId = modalHook.open({
              title: 'Удаление данных',
              bodyRenderer: () => (
                <Stack gap={3} style={{ justifyContent: 'center' }}>
                  <span style={{ textAlign: 'center' }}>
                    Удалить данные за <br />
                    <b>{getMonthText(cellProps.column)}</b>
                    <br /> по инструменту <br />
                    <b>{cellProps.row.toolName}</b>?
                  </span>
                  <Stack
                    direction="horizontal"
                    gap={3}
                    style={{ justifyContent: 'center' }}
                  >
                    <Button
                      variant="outline-warning"
                      onClick={() =>
                        handleDelete(modalId).then(resolve).catch(reject)
                      }
                    >
                      Удалить
                    </Button>
                    <Button
                      variant="outline-secondary"
                      onClick={() => modalHook.close(modalId)}
                    >
                      Отмена
                    </Button>
                  </Stack>
                </Stack>
              ),
              onClose: () => modalHook.close(modalId),
            });
          })
        }
        onDeleteRow={async () =>
          new Promise((resolve, reject) => {
            const modalId = modalHook.open({
              title: 'Удаление данных',
              bodyRenderer: () => (
                <Stack gap={3} style={{ justifyContent: 'center' }}>
                  <span style={{ textAlign: 'center' }}>
                    Удалить данные за <br />
                    <b>весь год</b>
                    <br /> по инструменту <br />
                    <b>{cellProps.row.toolName}</b>?
                  </span>
                  <Stack
                    direction="horizontal"
                    gap={3}
                    style={{ justifyContent: 'center' }}
                  >
                    <Button
                      variant="outline-warning"
                      onClick={() =>
                        handleDeleteRow(modalId).then(resolve).catch(reject)
                      }
                    >
                      Удалить
                    </Button>
                    <Button
                      variant="outline-secondary"
                      onClick={() => modalHook.close(modalId)}
                    >
                      Отмена
                    </Button>
                  </Stack>
                </Stack>
              ),
              onClose: () => modalHook.close(modalId),
            });
          })
        }
        editorRenderer={({ row, column, register }) => (
          <div style={{ padding: '0 4px' }}>
            <Stack direction="horizontal" gap={2}>
              <NumberInput
                autoFocus
                size="sm"
                {...register('amount')}
                style={{ height: 20, minHeight: 0, padding: '0 4px' }}
              />
              <div>р</div>
            </Stack>
          </div>
        )}
      />
    );
  };

  const onExpandedRowIdsChange = useCallback(
    (expandedRowIds: (string | number)[]) => {
      dispatch(setExpandedRows([...expandedRowIds]));
    },
    [dispatch],
  );

  const defaultExpandedRowIds = Object.values(rowsExpanded).filter((x) =>
    ['string', 'number'].includes(typeof x),
  );

  return (
    <Grid
      rows={data}
      columns={columns}
      getRowId={(row: any) => row.filialId}
      rootComponent={GridRootLayout}
    >
      <TreeDataState
        expandedRowIds={defaultExpandedRowIds}
        onExpandedRowIdsChange={onExpandedRowIdsChange}
      />
      <CustomTreeData getChildRows={getChildRows} />
      <VirtualTable
        columnExtensions={tableColumnExtensions}
        cellComponent={editableCellRenderer}
        noDataCellComponent={() => (
          <LoadingState columnCount={columns.length} loading={isLoading} />
        )}
      />
      <TableHeaderRow />
      <ChannelTreeColumn for="filialName" />
      <TableFixedColumns leftColumns={['filialName']} />
    </Grid>
  );
};

const LoadingState = ({
  loading,
  columnCount,
}: {
  loading: boolean;
  columnCount: number;
}) => (
  <td
    colSpan={columnCount}
    style={{ textAlign: 'center', verticalAlign: 'middle' }}
  >
    <big>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Загрузка...</span>
        </Spinner>
      ) : (
        <span>Нет данных</span>
      )}
    </big>
  </td>
);
