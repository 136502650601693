import { useFormik } from 'formik';
import moment from 'moment';
import { Task, ViewMode } from 'gantt-task-react';
import { GantInfoTypeId } from 'lib/enums/GantInfoType';
import { getMetaData } from 'pages/GanttPage/getMetaData';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GantInfoModel } from 'store/dto/GantInfoModel';
import {
  useCreateGanttMutation,
  useDeleteGanttMutation,
  useGanttInfoQuery,
  useUpdateGanttMutation,
} from 'store/services/gantt';
import { Button, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { yup } from 'lib';
import { useAuth } from 'hooks/useAuth';
import { useModal } from './useModal';

export type FormValues = {
  name: string;
  startDate: string;
  endDate: string;
  agent: string;
  note1: string;
  status: boolean;
};

interface GanttContextProps {
  ganttInfo: GantInfoModel[];
  tasks: Task[];
  editableTask: GantInfoModel | null;
  cancelEditTask: () => void;
  levels: { id: number; level: number }[];
  viewMode: ViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  createTask: (projectTask: Task, typeId?: GantInfoTypeId) => void;
  editTask: (task: Task) => void;
  editTaskDates: (task: Task) => void;
  saveTask: () => void;
  deleteTask: (task: Task) => void;
  form: ReturnType<typeof useFormik<FormValues>>;
}

const GanttContext = React.createContext<GanttContextProps>(
  {} as GanttContextProps,
);

export const useGantt = () => useContext(GanttContext);

export const GanttProvider: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const modalHook = useModal();
  const { projectId } = useParams();

  const [createGantt] = useCreateGanttMutation();
  const [updateGantt] = useUpdateGanttMutation();
  const [deleteGantt] = useDeleteGanttMutation();

  const [editableTask, setEditableTask] = useState<GantInfoModel | null>(null);
  const [viewMode, setViewMode] = useState(ViewMode.Month);

  const { isGuest } = useAuth();
  const { data: ganttInfo = [], refetch } = useGanttInfoQuery(isGuest);

  const createTask = useCallback(
    (task: Task, typeId: GantInfoTypeId = GantInfoTypeId.Task) => {
      const getParentId = () => {
        if (task.project && task.type === 'task') {
          return parseInt(task.project, 10);
        }
        return parseInt(task.id, 10);
      };

      setEditableTask({
        id: 0,
        parentId: getParentId(),
        name: '',
        typeId,
        agent: '',
        startDate: task.start.toISOString(),
        endDate: task.end.toISOString(),
        note1: '',
        note2: '',
        note3: '',
        status: false,
      });
    },
    [setEditableTask],
  );

  const visibleTasks: GantInfoModel[] = ganttInfo;

  const { tasks, levels } = getMetaData(visibleTasks, parseInt(projectId!, 10));

  function magicDate(this: yup.DateSchema, ref: any, msg?: string) {
    let startDate: any;
    // eslint-disable-next-line
    return this.test({
      name: 'magicDate',
      exclusive: false,
      params: { reference: ref ? ref.path : undefined },
      // eslint-disable-next-line
      test: function (value: any) {
        // eslint-disable-next-line
        const startDate = this.resolve(ref);

        const a = moment(startDate);
        const b = moment(value);
        const diff = b.diff(a, 'minutes');
        return diff > 0; // Ваша магия
      },
      // eslint-disable-next-line
      message: function (params: any) {
        const { value } = params;
        return msg || `Начальная дата: ${startDate}, конечная дата: ${value} `;
      },
    });
  }
  yup.addMethod(yup.date, 'magicDate', magicDate);

  function getMinDate() {
    return moment().subtract(5, 'years').format('l');
  }

  function getMaxDate() {
    return moment().add(10, 'years').format('l');
  }

  const validationSchema = yup.object().shape({
    name: yup.string().max(100).required().label('Поле'), // Наименование проекта
    agent: yup.string().max(50).required().label('Ответственный'),
    note1: yup.string().label('Примечание'),
    startDate: yup
      .date()
      .required()
      .min(getMinDate(), `Минимальная дата начала: ${getMinDate()}`)
      .label('Дата начала'),
    endDate: yup.lazy(() =>
      yup
        .date()
        .min(yup.ref('startDate'), 'Дата начала позже даты завершения.')
        .required()
        // @ts-ignore: Unreachable code error
        .magicDate(yup.ref('startDate'), 'Окончание раньше даты начала')
        .label('Дата завершения'),
    ),
  });

  const form = useFormik<FormValues>({
    initialValues: {
      name: editableTask ? editableTask.name! : '',
      startDate: editableTask ? editableTask.startDate! : '',
      endDate: editableTask ? editableTask.endDate! : '',
      agent: editableTask ? editableTask.agent! : '',
      note1: editableTask ? editableTask.note1! : '',
      status: editableTask ? editableTask.status! : false,
    },
    validationSchema,
    onSubmit: (values) => {
      if (form.dirty) {
        console.log(values);
      }
    },
  });

  useEffect(() => {
    if (editableTask) {
      form.setValues({
        name: editableTask.name!,
        startDate: editableTask.startDate!,
        endDate: editableTask.endDate!,
        agent: editableTask.agent!,
        note1: editableTask.note1!,
        status: editableTask.status!,
      });
    }
  }, [editableTask]);

  const saveTask = useCallback(async () => {
    if (editableTask === null) return;

    const gantBaseObj = {
      typeId: editableTask.typeId,
      agent: form.values.agent,
      startDate: form.values.startDate.endsWith('Z')
        ? form.values.startDate
        : `${form.values.startDate}.000Z`,
      endDate: form.values.endDate.endsWith('Z')
        ? form.values.endDate
        : `${form.values.endDate}.000Z`,
      name: form.values.name,
      parentId: editableTask.parentId,
      note1: form.values.note1,
      note2: editableTask.note2,
      note3: editableTask.note3,
      status: form.values.status ?? false,
    };

    if (editableTask?.id === 0) {
      await createGantt(gantBaseObj).unwrap();
    } else {
      const willUpdateTask = {
        id: editableTask.id,
        ...gantBaseObj,
      };

      saveMilestoneWithDatesFromChildren(willUpdateTask);
      await updateGantt(willUpdateTask).unwrap();
    }

    setEditableTask(null);

    refetch();
  }, [editableTask, createGantt, form, setEditableTask, refetch]);

  const handleDelete = useCallback(
    async (task: Task, modalId: number) => {
      try {
        await deleteGantt(task.id).unwrap();
        refetch();
        modalHook.close(modalId);

        modalHook.open({
          okButton: true,
          variant: 'success',
          title: 'Удаление данных',
          bodyRenderer: () => (
            <span>
              Задача <b>{task.name}</b> успешно удалена
            </span>
          ),
        });
      } catch (e) {
        modalHook.open({
          okButton: true,
          variant: 'error',
          title: 'Удаление данных',
          bodyRenderer: () => (
            <span>
              Ошибка при удалении задачи <b>{task.name}</b>
            </span>
          ),
        });
      }
    },
    [modalHook, deleteGantt, refetch],
  );

  const deleteTask = useCallback(
    async (task: Task) =>
      new Promise((resolve, reject) => {
        const modalId = modalHook.open({
          title: 'Удаление задачи',
          bodyRenderer: () => (
            <Stack gap={3} style={{ justifyContent: 'center' }}>
              <span style={{ textAlign: 'center' }}>
                Вы уверены, что хотите удалить задачу <b>{task.name}</b> и
                связанные подзадачи?
              </span>
              <Stack
                direction="horizontal"
                gap={3}
                style={{ justifyContent: 'center' }}
              >
                <Button
                  variant="outline-warning"
                  onClick={() =>
                    handleDelete(task, modalId).then(resolve).catch(reject)
                  }
                >
                  Удалить
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => modalHook.close(modalId)}
                >
                  Отмена
                </Button>
              </Stack>
            </Stack>
          ),
          onClose: () => modalHook.close(modalId),
        });
      }),
    [modalHook, handleDelete],
  );

  const editTask = useCallback(
    (task: Task) => {
      if (editableTask === null) {
        const editableTask = ganttInfo.find(
          (x) => x.id.toString() === task.id,
        )!;
        setEditableTask(editableTask);
      }
    },
    [setEditableTask, ganttInfo, editableTask],
  );

  const editTaskDates = useCallback(
    async (task: Task) => {
      const origTask = ganttInfo.find((x) => x.id.toString() === task.id)!;

      const willUpdateTask = {
        ...origTask,
        startDate: task.start.toISOString(),
        endDate: task.end.toISOString(),
      };

      saveMilestoneWithDatesFromChildren(willUpdateTask);
      await updateGantt(willUpdateTask).unwrap();
      refetch();
    },

    [setEditableTask, ganttInfo, editableTask, form],
  );

  const saveMilestoneWithDatesFromChildren = async (
    childTask: GantInfoModel,
  ) => {
    if (
      !childTask ||
      childTask.typeId !== GantInfoTypeId.Task ||
      !childTask.parentId
    ) {
      return;
    }

    const parentTask = ganttInfo.find((t) => t.id === childTask.parentId);

    if (!parentTask || parentTask.typeId !== GantInfoTypeId.Milestone) {
      return;
    }

    const childrenTaskList = ganttInfo.filter(
      (t) => t.parentId === childTask.parentId,
    );

    if (!childrenTaskList.length) {
      return;
    }

    const dateArr: Array<string> = [];

    childrenTaskList.forEach((t) => {
      if (childTask.id === t.id) {
        t = childTask;
      }
      if (t.startDate) {
        dateArr.push(t.startDate);
      }
      if (t.endDate) {
        dateArr.push(t.endDate);
      }
    });

    dateArr.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    const minStartDate = dateArr[0];
    const maxEndDate = dateArr[dateArr.length - 1];

    if (
      minStartDate === parentTask.startDate &&
      maxEndDate === parentTask.endDate
    ) {
      return;
    }

    const parentTaskWithNewDates = {
      ...parentTask,
      startDate: minStartDate,
      endDate: maxEndDate,
    };

    await updateGantt(parentTaskWithNewDates).unwrap();
  };

  const cancelEditTask = useCallback(() => {
    setEditableTask(null);
  }, [setEditableTask]);

  return (
    <GanttContext.Provider
      value={{
        ganttInfo,
        tasks,
        editableTask,
        cancelEditTask,
        levels,
        viewMode,
        setViewMode,
        createTask,
        editTask,
        editTaskDates,
        saveTask,
        deleteTask,
        form,
      }}
    >
      {children}
    </GanttContext.Provider>
  );
};
