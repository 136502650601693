import styled from 'styled-components';

export const GridRootLayout = styled.div`
  flex: 1;
  overflow: auto;

  & > .table-responsive {
    height: 100% !important;
  }
`;
