import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserInformation } from 'store/dto/AuthResponse';
import { RootState } from 'store/store';

type AuthState = {
  token: string | null;
  user: UserInformation | null;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { token, user },
      }: PayloadAction<{ token: string; user: UserInformation }>,
    ) => {
      state.token = token;
      state.user = user;
    },
    clearCredentials: (state) => ({ ...state, token: null, user: null }),
  },
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;

export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.user;
