export enum GantInfoTypeId {
  Project = 1,
  Milestone = 2,
  Task = 3,
}

export enum GantInfoTypeText {
  Project = 'project',
  Milestone = 'milestone',
  Task = 'task',
}

const taskLevelLabels: Record<string, string> = {
  '1': 'проект',
  '2': 'подпроект',
  '3': 'задача',
};

const taskLevelLabelsRo: Record<string, string> = {
  '1': 'проекта',
  '2': 'подпроекта',
  '3': 'задачи',
};

export const getLevelLabelRo = (id: number | string) => {
  const typeId = String(id);

  if (Object.keys(taskLevelLabelsRo).includes(typeId)) {
    return taskLevelLabelsRo[typeId];
  }
  return taskLevelLabelsRo['3'];
};
